

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import About from './pages/about';
import Tabledownload from './pages/tabledownload';

import Apidata from './pages/apidata';
import Contact from './pages/contact';
import Events from './pages/events';
import Register from './pages/register';
import Home from './pages/index';
import ScrollToTop from './ScrollToTop'; // Import your ScrollToTop component
import Login from './Login'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop /> {/* Add this line */}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} /> 
          <Route path="/apidata" element={<Apidata />} /> 
          <Route path="/tabledownload" element={<Tabledownload />} /> 
          <Route path="/register" element={<Register />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
        </Routes>
         <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

