import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

function FormComponent() {
 



  const [femaleTeacherMobile, setFemaleTeacherMobile] = useState("");
  const [isValid, setIsValid] = useState(true); // Track if the fields are valid
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission state

  const schoolOptions = [
    'Seth Anandram Jaipuria School, Kanpur',
    'Seth Anandram Jaipuria School, Ghaziabad',
    'Seth Anandram Jaipuria School, Lucknow',
    'Seth Anandram Jaipuria School, Alambagh',
    'Seth Anandram Jaipuria School, Unnao',
    'Seth Anandram Jaipuria School, Varanasi',
    'Seth Anandram Jaipuria School, Sitarganj',
    'Seth Anandram Jaipuria School, Satna',
    'Seth Anandram Jaipuria School, Mirzapur',
    'Seth Anandram Jaipuria School, Dibiyapur',
    'Seth Anandram Jaipuria School, Nanpara',
    'Seth Anandram Jaipuria School, Greater Noida',
    'Seth Anandram Jaipuria School, Amethi',
    'Seth Anandram Jaipuria School, Ved Campus Barabanki',
    'Seth Anandram Jaipuria School, Sadhu Campus Barabanki',
    'Seth Anandram Jaipuria School, GNITM Campus Barabanki',
    'Seth Anandram Jaipuria School, Barra Kanpur',
    'Seth Anandram Jaipuria School, New Jajmau Kanpur',
    'Seth Anandram Jaipuria School, Majnai Ayodhya',
    'Seth Anandram Jaipuria School, Sidhauli',
    'Seth Anandram Jaipuria School, Deoria',
  ];

  const [agreement2] = useState(''); // Or with an appropriate default value
  const [agreement1] = useState(''); // Or with an appropriate default value
  const [agreement, setAgreementChecked] = useState(''); // Or with an appropriate default value

  // const [school_name, setSchoolName] = useState('');
  const [campus, setCampus] = useState('');
  const [principal_email, setPrincipalEmail] = useState('');
  const [principal_name, setPrincipalName] = useState('');
  const [principal_mobile, setPrincipalMobile] = useState('');
  const [sports_incharge, setSportsIncharge] = useState('');
  const [incharge_mobile, setInchargeMobile] = useState('');
  const [male_teacher, setMaleTeacher] = useState('');
  const [male_teacher_mobile, setMaleTeacherMobile] = useState('');
  const [female_teacher, setFemaleTeacher] = useState('');

  const [female_teacher_mobile, setfemaleTeacherMobile] = useState('');
  const [u12_boy_name_80mtr,  set80MeterRaceU12BoyName] = useState('');
  const [u12_boy_classes_80mtr, set80MeterRaceU12BoyClasses] = useState('');
  const [u12_boy_dob_80mtr, set80MeterRaceU12BoyDob] = useState('');
  const [u12_girl_name_80mtr, set80MeterRaceU12GirlName] = useState('');
  const [u12_girl_classes_80mtr, set80MeterRaceU12GirlClasses] = useState('');
  const [u12_girl_dob_80mtr, set80MeterRaceU12GirlDob] = useState('');
  // 100 mtr
  const [u14_boy_name_100mtr,  set100MeterRaceU14BoyName] = useState('');
  const [u14_boy_classes_100mtr, set100MeterRaceU14BoyClasses] = useState('');
  const [u14_boy_dob_100mtr, set100MeterRaceU14BoyDob] = useState('');
  const [u14_girl_name_100mtr, set100MeterRaceU14GirlName] = useState('');
  const [u14_girl_classes_100mtr, set100MeterRaceU14GirlClasses] = useState('');
  const [u14_girl_dob_100mtr, set100MeterRaceU14GirlDob] = useState('');
  const [u17_boy_name_100mtr,  set100MeterRaceU17BoyName] = useState('');
  const [u17_boy_classes_100mtr, set100MeterRaceU17BoyClasses] = useState('');
  const [u17_boy_dob_100mtr, set100MeterRaceU17BoyDob] = useState('');
  const [u17_girl_name_100mtr, set100MeterRaceU17GirlName] = useState('');
  const [u17_girl_classes_100mtr, set100MeterRaceU17GirlClasses] = useState('');
  const [u17_girl_dob_100mtr, set100MeterRaceU17GirlDob] = useState('');
  // 200
  const [u14_boy_name_200mtr,  set200MeterRaceU14BoyName] = useState('');
  const [u14_boy_classes_200mtr, set200MeterRaceU14BoyClasses] = useState('');
  const [u14_boy_dob_200mtr, set200MeterRaceU14BoyDob] = useState('');
  const [u14_girl_name_200mtr, set200MeterRaceU14GirlName] = useState('');
  const [u14_girl_classes_200mtr, set200MeterRaceU14GirlClasses] = useState('');
  const [u14_girl_dob_200mtr, set200MeterRaceU14GirlDob] = useState('');
  const [u17_boy_name_200mtr,  set200MeterRaceU17BoyName] = useState('');
  const [u17_boy_classes_200mtr, set200MeterRaceU17BoyClasses] = useState('');
  const [u17_boy_dob_200mtr, set200MeterRaceU17BoyDob] = useState('');
  const [u17_girl_name_200mtr, set200MeterRaceU17GirlName] = useState('');
  const [u17_girl_classes_200mtr, set200MeterRaceU17GirlClasses] = useState('');
  const [u17_girl_dob_200mtr, set200MeterRaceU17GirlDob] = useState('');
   // 400
   const [u14_boy_name_400mtr,  set400MeterRaceU14BoyName] = useState('');
   const [u14_boy_classes_400mtr, set400MeterRaceU14BoyClasses] = useState('');
   const [u14_boy_dob_400mtr, set400MeterRaceU14BoyDob] = useState('');
   const [u14_girl_name_400mtr, set400MeterRaceU14GirlName] = useState('');
   const [u14_girl_classes_400mtr, set400MeterRaceU14GirlClasses] = useState('');
   const [u14_girl_dob_400mtr, set400MeterRaceU14GirlDob] = useState('');
   const [u17_boy_name_400mtr,  set400MeterRaceU17BoyName] = useState('');
   const [u17_boy_classes_400mtr, set400MeterRaceU17BoyClasses] = useState('');
   const [u17_boy_dob_400mtr, set400MeterRaceU17BoyDob] = useState('');
   const [u17_girl_name_400mtr, set400MeterRaceU17GirlName] = useState('');
   const [u17_girl_classes_400mtr, set400MeterRaceU17GirlClasses] = useState('');
   const [u17_girl_dob_400mtr, set400MeterRaceU17GirlDob] = useState('');

    // hurdle race
    const [u17_boy_name_hurdlerace,  sethurdleraceU17BoyName] = useState('');
    const [u17_boy_classes_hurdlerace, sethurdleraceU17BoyClasses] = useState('');
    const [u17_boy_dob_hurdlerace, sethurdleraceU17BoyDob] = useState('');
    const [u17_girl_name_hurdlerace, sethurdleraceU17GirlName] = useState('');
    const [u17_girl_classes_hurdlerace, sethurdleraceU17GirlClasses] = useState('');
    const [u17_girl_dob_hurdlerace, sethurdleraceU17GirlDob] = useState('');
// long jump

const [u14_boy_name_longjump,  setLongJumpU14BoyName] = useState('');
const [u14_boy_classes_longjump, setLongJumpU14BoyClasses] = useState('');
const [u14_boy_dob_longjump, setLongJumpU14BoyDob] = useState('');
const [u14_girl_name_longjump, setLongJumpU14GirlName] = useState('');
const [u14_girl_classes_longjump, setLongJumpU14GirlClasses] = useState('');
const [u14_girl_dob_longjump, setLongJumpU14GirlDob] = useState('');
const [u17_boy_name_longjump,  setLongJumpU17BoyName] = useState('');
const [u17_boy_classes_longjump, setLongJumpU17BoyClasses] = useState('');
const [u17_boy_dob_longjump, setLongJumpU17BoyDob] = useState('');
const [u17_girl_name_longjump, setLongJumpU17GirlName] = useState('');
const [u17_girl_classes_longjump, setLongJumpU17GirlClasses] = useState('');
const [u17_girl_dob_longjump, setLongJump17GirlDob] = useState('');

// / RelayRace1

const [u14_boy_name_relay_race_1,  setRelayRaceU14BoyName1] = useState('');
const [u14_boy_classes_relay_race_1, setRelayRaceU14BoyClasses1] = useState('');
const [u14_boy_dob_relay_race_1, setRelayRaceU14BoyDob1] = useState('');
const [u14_girl_name_relay_race_1, setRelayRaceU14GirlName1] = useState('');
const [u14_girl_classes_relay_race_1, setRelayRaceU14GirlClasses1] = useState('');
const [u14_girl_dob_relay_race_1, setRelayRaceU14GirlDob1] = useState('');
const [u17_boy_name_relay_race_1,  setRelayRaceU17BoyName1] = useState('');
const [u17_boy_classes_relay_race_1, setRelayRaceU17BoyClasses1] = useState('');
const [u17_boy_dob_relay_race_1, setRelayRaceU17BoyDob1] = useState('');
const [u17_girl_name_relay_race_1, setRelayRaceU17GirlName1] = useState('');
const [u17_girl_classes_relay_race_1, setRelayRaceU17GirlClasses1] = useState('');
const [u17_girl_dob_relay_race_1, setRelayRaceU17GirlDob1] = useState('');

// / RelayRace2

const [u14_boy_name_relay_race_2,  setRelayRaceU14BoyName2] = useState('');
const [u14_boy_classes_relay_race_2, setRelayRaceU14BoyClasses2] = useState('');
const [u14_boy_dob_relay_race_2, setRelayRaceU14BoyDob2] = useState('');
const [u14_girl_name_relay_race_2, setRelayRaceU14GirlName2] = useState('');
const [u14_girl_classes_relay_race_2, setRelayRaceU14GirlClasses2] = useState('');
const [u14_girl_dob_relay_race_2, setRelayRaceU14GirlDob2] = useState('');
const [u17_boy_name_relay_race_2,  setRelayRaceU17BoyName2] = useState('');
const [u17_boy_classes_relay_race_2, setRelayRaceU17BoyClasses2] = useState('');
const [u17_boy_dob_relay_race_2, setRelayRaceU17BoyDob2] = useState('');
const [u17_girl_name_relay_race_2, setRelayRaceU17GirlName2] = useState('');
const [u17_girl_classes_relay_race_2, setRelayRaceU17GirlClasses2] = useState('');
const [u17_girl_dob_relay_race_2, setRelayRaceU17GirlDob2] = useState('');

// / RelayRace3

const [u14_boy_name_relay_race_3,  setRelayRaceU14BoyName3] = useState('');
const [u14_boy_classes_relay_race_3, setRelayRaceU14BoyClasses3] = useState('');
const [u14_boy_dob_relay_race_3, setRelayRaceU14BoyDob3] = useState('');
const [u14_girl_name_relay_race_3, setRelayRaceU14GirlName3] = useState('');
const [u14_girl_classes_relay_race_3, setRelayRaceU14GirlClasses3] = useState('');
const [u14_girl_dob_relay_race_3, setRelayRaceU14GirlDob3] = useState('');
const [u17_boy_name_relay_race_3,  setRelayRaceU17BoyName3] = useState('');
const [u17_boy_classes_relay_race_3, setRelayRaceU17BoyClasses3] = useState('');
const [u17_boy_dob_relay_race_3, setRelayRaceU17BoyDob3] = useState('');
const [u17_girl_name_relay_race_3, setRelayRaceU17GirlName3] = useState('');
const [u17_girl_classes_relay_race_3, setRelayRaceU17GirlClasses3] = useState('');
const [u17_girl_dob_relay_race_3, setRelayRaceU17GirlDob3] = useState('');

// / RelayRace4

const [u14_boy_name_relay_race_4,  setRelayRaceU14BoyName4] = useState('');
const [u14_boy_classes_relay_race_4, setRelayRaceU14BoyClasses4] = useState('');
const [u14_boy_dob_relay_race_4, setRelayRaceU14BoyDob4] = useState('');
const [u14_girl_name_relay_race_4, setRelayRaceU14GirlName4] = useState('');
const [u14_girl_classes_relay_race_4, setRelayRaceU14GirlClasses4] = useState('');
const [u14_girl_dob_relay_race_4, setRelayRaceU14GirlDob4] = useState('');
const [u17_boy_name_relay_race_4,  setRelayRaceU17BoyName4] = useState('');
const [u17_boy_classes_relay_race_4, setRelayRaceU17BoyClasses4] = useState('');
const [u17_boy_dob_relay_race_4, setRelayRaceU17BoyDob4] = useState('');
const [u17_girl_name_relay_race_4, setRelayRaceU17GirlName4] = useState('');
const [u17_girl_classes_relay_race_4, setRelayRaceU17GirlClasses4] = useState('');
const [u17_girl_dob_relay_race_4, setRelayRaceU17GirlDob4] = useState('');


// / short put

const [u14_boy_name_short_put,  setShortPutU14BoyName] = useState('');
const [u14_boy_classes_short_put, setShortPutU14BoyClasses] = useState('');
const [u14_boy_dob_short_put, setShortPutU14BoyDob] = useState('');
const [u14_girl_name_short_put, setShortPutU14GirlName] = useState('');
const [u14_girl_classes_short_put, setShortPutU14GirlClasses] = useState('');
const [u14_girl_dob_short_put, setShortPutU14GirlDob] = useState('');
const [u17_boy_name_short_put,  setShortPutU17BoyName] = useState('');
const [u17_boy_classes_short_put, setShortPutU17BoyClasses] = useState('');
const [u17_boy_dob_short_put, setShortPutU17BoyDob] = useState('');
const [u17_girl_name_short_put, setShortPutU17GirlName] = useState('');
const [u17_girl_classes_short_put, setShortPutU17GirlClasses] = useState('');
const [u17_girl_dob_short_put, setShortPut17GirlDob] = useState('');

// / badminton

const [u14_boy_name_badminton,  setBadmintonU14BoyName] = useState('');
const [u14_boy_classes_badminton, setBadmintonU14BoyClasses] = useState('');
const [u14_boy_dob_badminton, setBadmintonU14BoyDob] = useState('');
const [u14_girl_name_badminton, setBadmintonU14GirlName] = useState('');
const [u14_girl_classes_badminton, setBadmintonU14GirlClasses] = useState('');
const [u14_girl_dob_badminton, setBadmintonU14GirlDob] = useState('');
const [u17_boy_name_badminton,  setBadmintonU17BoyName] = useState('');
const [u17_boy_classes_badminton, setBadmintonU17BoyClasses] = useState('');
const [u17_boy_dob_badminton, setBadmintonU17BoyDob] = useState('');
const [u17_girl_name_badminton, setBadmintonU17GirlName] = useState('');
const [u17_girl_classes_badminton, setBadmintonU17GirlClasses] = useState('');
const [u17_girl_dob_badminton ,setBadmintonU17GirlDob] = useState('');


// / chess

const [u14_boy_name_chess,  setChessU14BoyName] = useState('');
const [u14_boy_classes_chess, setChessU14BoyClasses] = useState('');
const [u14_boy_dob_chess, setChessU14BoyDob] = useState('');
const [u14_girl_name_chess, setChessU14GirlName] = useState('');
const [u14_girl_classes_chess, setChessU14GirlClasses] = useState('');
const [u14_girl_dob_chess, setChess14GirlDob] = useState('');
const [u17_boy_name_chess,  setChessU17BoyName] = useState('');
const [u17_boy_classes_chess, setChessU17BoyClasses] = useState('');
const [u17_boy_dob_chess, setChessU17BoyDob] = useState('');
const [u17_girl_name_chess, setChessU17GirlName] = useState('');
const [u17_girl_classes_chess, setChessU17GirlClasses] = useState('');
const [u17_girl_dob_chess ,setChessU17GirlDob] = useState('');

// / / table tenis

const [u14_boy_name_table_tenis,  setTabelTenisU14BoyName] = useState('');
const [u14_boy_classes_table_tenis, setTabelTenisU14BoyClasses] = useState('');
const [u14_boy_dob_table_tenis, setTabelTenisU14BoyDob] = useState('');
const [u14_girl_name_table_tenis, setTabelTenisU14GirlName] = useState('');
const [u14_girl_classes_table_tenis, setTabelTenisU14GirlClasses] = useState('');
const [u14_girl_dob_table_tenis, setTabelTenisU14GirlDob] = useState('');
const [u17_boy_name_table_tenis,  setTabelTenisU17BoyName] = useState('');
const [u17_boy_classes_table_tenis, setTabelTenisU17BoyClasses] = useState('');
const [u17_boy_dob_table_tenis, setTabelTenisU17BoyDob] = useState('');
const [u17_girl_name_table_tenis, setTabelTenisU17GirlName] = useState('');
const [u17_girl_classes_table_tenis, setTabelTenisU17GirlClasses] = useState('');
const [u17_girl_dob_table_tenis ,setTabelTenisU17GirlDob] = useState('');

const [isDeclarationChecked, setDeclarationChecked] = useState(false);
const [isAgreementChecked1, setAgreementChecked1] = useState(false);
const [isAgreementChecked2, setAgreementChecked2] = useState(false);
//const [isSubmitted, setIsSubmitted] = useState(false);
const navigate = useNavigate();

// Function to validate the email
const validateEmail = (email) => {
  // Basic email validation and domain check for @jaipuria.school or @jaipuria.edu.in
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format check
  return emailPattern.test(email) && (email.endsWith("@jaipuria.school") || email.endsWith("@jaipuria.edu.in"));
};


  // Update email and validate onChange
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setPrincipalEmail(email);
    setIsValid(validateEmail(email)); // Validate email as user types
  };


  // Validate mobile number format (10 digits)
  const validateMobile = (mobile) => {
    const regex = /^\d{10}$/;
    return regex.test(mobile);
  };

  const school_name="SETH ANANDRAM JAIPURIA SCHOOL";

  // Submit handle
  const handleSubmit = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0); // scroll to the top
    setIsSubmitted(true); // Set to true when submitting


// added on17-10-24

    const valid = validateEmail(principal_email);
    setIsValid(valid);

    if (valid) {
      // Handle successful form submission
      console.log("Form submitted successfully");
    }
  

    // Validation checks
    if (principal_name.trim() === "" || !validateEmail(principal_email) || !validateMobile(principal_mobile)) {
      setIsValid(false); // Set invalid if any field is not valid
    } else {
      setIsValid(true); // Set valid if all fields are correct
      // Proceed with form submission logic

      
  const userData = {
    school_name,
    campus,
    principal_email,
    principal_name,
    principal_mobile,
    sports_incharge,
    incharge_mobile,
    male_teacher,
    male_teacher_mobile,
    female_teacher,
    female_teacher_mobile,
    u12_boy_name_80mtr,
    u12_boy_classes_80mtr,
    u12_boy_dob_80mtr,
    u12_girl_name_80mtr,
    u12_girl_classes_80mtr,
    u12_girl_dob_80mtr,
    u14_boy_name_100mtr,
    u14_boy_classes_100mtr,
    u14_girl_classes_100mtr,
    u14_boy_dob_100mtr,
    u14_girl_name_100mtr,
    u14_girl_dob_100mtr,
    u17_boy_name_100mtr,
    u17_boy_classes_100mtr,
    u17_boy_dob_100mtr,
    u17_girl_name_100mtr,
    u17_girl_classes_100mtr,
    u17_girl_dob_100mtr,
    // 200
    u14_boy_name_200mtr,
    u14_boy_classes_200mtr,
    u14_girl_classes_200mtr,
    u14_boy_dob_200mtr,
    u14_girl_name_200mtr,
    u14_girl_dob_200mtr,
    u17_boy_name_200mtr,
    u17_boy_classes_200mtr,
    u17_boy_dob_200mtr,
    u17_girl_name_200mtr,
    u17_girl_classes_200mtr,
    u17_girl_dob_200mtr,
     // 400
     u14_boy_name_400mtr,
     u14_boy_classes_400mtr,
     u14_girl_classes_400mtr,
     u14_boy_dob_400mtr,
     u14_girl_name_400mtr,
     u14_girl_dob_400mtr,
     u17_boy_name_400mtr,
     u17_boy_classes_400mtr,
     u17_boy_dob_400mtr,
     u17_girl_name_400mtr,
     u17_girl_classes_400mtr,
     u17_girl_dob_400mtr,
    //  hurdle race

    u17_boy_name_hurdlerace,
    u17_boy_classes_hurdlerace,
    u17_boy_dob_hurdlerace,
    u17_girl_name_hurdlerace,
    u17_girl_classes_hurdlerace,
    u17_girl_dob_hurdlerace,
    // long jump
    u14_boy_name_longjump,
    u14_boy_classes_longjump,
    u14_girl_classes_longjump,
    u14_boy_dob_longjump,
    u14_girl_name_longjump,
    u14_girl_dob_longjump,
    u17_boy_name_longjump,
    u17_boy_classes_longjump,
    u17_boy_dob_longjump,
    u17_girl_name_longjump,
    u17_girl_classes_longjump,
    u17_girl_dob_longjump,
    // relay race 1
     u14_boy_name_relay_race_1,
     u14_boy_classes_relay_race_1,
     u14_girl_classes_relay_race_1,
     u14_boy_dob_relay_race_1,
     u14_girl_name_relay_race_1,
     u14_girl_dob_relay_race_1, 
     u17_boy_name_relay_race_1,
     u17_boy_classes_relay_race_1,
     u17_boy_dob_relay_race_1,
     u17_girl_name_relay_race_1,
     u17_girl_classes_relay_race_1,
     u17_girl_dob_relay_race_1,
      // relay race 2
      u14_boy_name_relay_race_2,
      u14_boy_classes_relay_race_2,
      u14_girl_classes_relay_race_2,
      u14_boy_dob_relay_race_2,
      u14_girl_name_relay_race_2,
      u14_girl_dob_relay_race_2, 
      u17_boy_name_relay_race_2,
      u17_boy_classes_relay_race_2,
      u17_boy_dob_relay_race_2,
      u17_girl_name_relay_race_2,
      u17_girl_classes_relay_race_2,
      u17_girl_dob_relay_race_2,
      // relay race 3
      u14_boy_name_relay_race_3,
      u14_boy_classes_relay_race_3,
      u14_girl_classes_relay_race_3,
      u14_boy_dob_relay_race_3,
      u14_girl_name_relay_race_3,
      u14_girl_dob_relay_race_3, 
      u17_boy_name_relay_race_3,
      u17_boy_classes_relay_race_3,
      u17_boy_dob_relay_race_3,
      u17_girl_name_relay_race_3,
      u17_girl_classes_relay_race_3,
      u17_girl_dob_relay_race_3,
       // relay race 4
       u14_boy_name_relay_race_4,
       u14_boy_classes_relay_race_4,
       u14_girl_classes_relay_race_4,
       u14_boy_dob_relay_race_4,
       u14_girl_name_relay_race_4,
       u14_girl_dob_relay_race_4, 
       u17_boy_name_relay_race_4,
       u17_boy_classes_relay_race_4,
       u17_boy_dob_relay_race_4,
       u17_girl_name_relay_race_4,
       u17_girl_classes_relay_race_4,
       u17_girl_dob_relay_race_4,
    //  short Put
    u14_boy_name_short_put,
    u14_boy_classes_short_put,
    u14_girl_classes_short_put,
    u14_boy_dob_short_put,
    u14_girl_name_short_put,
    u14_girl_dob_short_put,
    u17_boy_name_short_put,
    u17_boy_classes_short_put,
    u17_boy_dob_short_put,
    u17_girl_name_short_put,
    u17_girl_classes_short_put,
    u17_girl_dob_short_put,

     //  badminton
     u14_boy_name_badminton,
     u14_boy_classes_badminton,
     u14_girl_classes_badminton,
     u14_boy_dob_badminton,
     u14_girl_name_badminton,
     u14_girl_dob_badminton,
     u17_boy_name_badminton,
     u17_boy_classes_badminton,
     u17_boy_dob_badminton,
     u17_girl_name_badminton,
     u17_girl_classes_badminton,
     u17_girl_dob_badminton,

      // chess
      u14_boy_name_chess,
      u14_boy_classes_chess,
      u14_girl_classes_chess,
      u14_boy_dob_chess,
      u14_girl_name_chess,
      u14_girl_dob_chess,
      u17_boy_name_chess,
      u17_boy_classes_chess,
      u17_boy_dob_chess,
      u17_girl_name_chess,
      u17_girl_classes_chess,
      u17_girl_dob_chess,

       // table_tenis
       u14_boy_name_table_tenis,
       u14_boy_classes_table_tenis,
       u14_girl_classes_table_tenis,
       u14_boy_dob_table_tenis,
       u14_girl_name_table_tenis,
       u14_girl_dob_table_tenis,
       u17_boy_name_table_tenis,
       u17_boy_classes_table_tenis,
       u17_boy_dob_table_tenis,
       u17_girl_name_table_tenis,
       u17_girl_classes_table_tenis,
       u17_girl_dob_table_tenis,

    
  };


      // Sending data to your API
axios.post('https://testingnew.sttar.in/api/athletica', userData)
.then(response => {
    Swal.fire(
        'Submitted!',
        'Your form has been submitted successfully.',
        'success'
    );
    // sendEmail(response.principal_email)
    navigate('/'); // Change to the desired route
})
.catch(error => {
    console.error('Error submitting data:', error.response ? error.response.data : error.message);
    Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'There was an error submitting your form.',
    });
    // Handle error
});
     
    }
  }; // Submit handle end




  // const id = 1; // Initialize the 'id' variable
  // const [user, setUser] = useState(null);
  // const sendEmail = async (id) => {
  //   try {
  //     const response = await axios.get(`testingnew.star.in/athletica-send-mail/${id}`);
  
  //     if (response.status === 200) {
  //       // console.log('Email sent successfully');
  //     } else {
  //       console.error('Failed to send email. Response status:', response.status);
  //       // console.log('Response data:', response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error sending email:', error.message);
  //   }
  // };



  // const fetchUser = async () => {
  //   try {
  //     const response = await axios.get(`testingnew.star.in/athletica/${id}`);
  //     setUser(response.data);
  //     // console.log("rrrrr",response.data.id)
  //     sendEmail(response.data.id);
  //     // console.log("Ratna ",response.data)
  //   } catch (error) {
  //     console.error("Error fetching Referral:", error);
  //   }
  // };

  return (
    <section className="how-it-works">
    <div className="inner-page">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="school_name">School Name:</label>
              <input
                type="text"
                id="school_name"
                name="school_name"
                value="SETH ANANDRAM JAIPURIA SCHOOL"
                // value ="SETH ANANDRAM JAIPURIA SCHOOL"
                // onChange={(event) => setSchoolName(event.target.value)}
                // style={{
                //   borderColor: !isValid && school_name.trim() === "" ? "red" : "",
                // }}
              />
              {/* {!isValid && school_name.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )} */}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="campus">Campus</label>
              <select
                id="campus"
                name="campus"
                value={campus}
                onChange={(event) => setCampus(event.target.value)}
              >
                <option value="" disabled>Select a campus</option>
                {schoolOptions.map((school, index) => (
                  <option key={index} value={school}>
                    {school}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">


            {/* <div className="form-group">
              <label htmlFor="principal_email">Principal Email</label>
              <input
                type="email"
                id="principal_email"
                name="principal_email"
                value={principal_email}
                onChange={(event) => setPrincipalEmail(event.target.value)}
                
                className={validateEmail(principal_email) || !isSubmitted ? '' : 'is-invalid'}
              />
                {!isValid && principal_email.trim() === "" && (
                <p style={{ color: "red" }}>Please enter an email address ending with @jaipuria.school required</p>
              )}
            </div> */}
              <div className="form-group">
        <label htmlFor="principal_email">Principal Email</label>
        <input
          type="email"
          id="principal_email"
          name="principal_email"
          value={principal_email}
          onChange={handleEmailChange} // Validate on each change
          className={isValid || !isSubmitted ? '' : 'is-invalid'}
        />
        {/* Error message for invalid email */}
        {!isValid && isSubmitted && (
  <p style={{ color: "red" }}>
    Please enter a valid email address ending with @jaipuria.school or @jaipuria.edu.in
  </p>
)}

      </div>



          </div>
          
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="principal_name">Principal Name:</label>
              <input
                type="text"
                id="principal_name"
                name="principal_name"
               value={principal_name}
              
                onChange={(event) => setPrincipalName(event.target.value)}
                style={{
                  borderColor: !isValid && principal_name.trim() === "" ? "red" : "",
                }}
              />
              {!isValid && principal_name.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="principal_mobile">Principal Mobile Number</label>
              <input
                type="number"
                id="principal_mobile"
                name="principal_mobile"
                value={principal_mobile}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.length <= 10) {  // Limit to 10 digits
                    setPrincipalMobile(value);
                  }
                }} 
                className={validateMobile(principal_mobile) || !isSubmitted ? '' : 'is-invalid'}
              />
              {!isValid && principal_mobile.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
          </div>

        
        </div>

        <div className="row">
        <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="sports_incharge">Sports Incharge Name</label>
              <input
                type="text"
                id="sports_incharge"
                name="sports_incharge"
                value={sports_incharge}
                onChange={(event) => setSportsIncharge(event.target.value)}
                className={validateMobile(sports_incharge) || !isSubmitted ? '' : 'is-invalid'}
              />
               {!isValid && sports_incharge.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="incharge_mobile">Sports Incharge Mobile Number</label>
              <input
                type="number"
                id="incharge_mobile"
                name="incharge_mobile"
                value={incharge_mobile}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.length <= 10) {  // Limit to 10 digits
                    setInchargeMobile(value);
                  }
                }}
               
                className={validateMobile(incharge_mobile) || !isSubmitted ? '' : 'is-invalid'}
              />
                {!isValid && incharge_mobile.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
          </div>

          
        </div>

        <div className="row">
        <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="male_teacher">Teacher Accompanying (Male)</label>
              <input
                type="text"
                id="male_teacher"
                name="male_teacher"
                value={male_teacher}
                onChange={(event) => setMaleTeacher(event.target.value)}
               
                className={male_teacher.trim() === "" && isSubmitted ? 'is-invalid' : ''}
              />
               {!isValid && male_teacher.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="male_teacher_mobile">Teacher (Male) Mobile Number</label>
              <input
                type="number"
                id="male_teacher_mobile"
                name="male_teacher_mobile"
                value={male_teacher_mobile}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.length <= 10) {  // Limit to 10 digits
                    setMaleTeacherMobile(value);
                  }
                }}
               
                className={validateMobile(male_teacher_mobile) || !isSubmitted ? '' : 'is-invalid'}
              />
               {!isValid && male_teacher_mobile.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="female_teacher">Teacher Accompanying (Female)</label>
              <input
                type="text"
                id="female_teacher"
                name="female_teacher"
                value={female_teacher}
                onChange={(event) => setFemaleTeacher(event.target.value)}
              
                className={female_teacher.trim() === "" && isSubmitted ? 'is-invalid' : ''}
              />
               {!isValid && female_teacher.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="female_teacher_mobile">Teacher (Female) Mobile Number</label>
              <input
                type="text"
                id="female_teacher_mobile"
                name="female_teacher_mobile"
                value={female_teacher_mobile}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.length <= 10) {  // Limit to 10 digits
                    setfemaleTeacherMobile(value);
                  }
                }}
              
                className={validateMobile(female_teacher_mobile) || !isSubmitted ? '' : 'is-invalid'}
              />
               {!isValid && female_teacher_mobile.trim() === "" && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
          </div>
        </div>

        <div className="section-title">Athletics </div>
    <label>80 Meter Race</label>
    {/* Athletics Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth</th>
          <th>Name (Girls)</th>
          <th>Class </th>
          <th>Date of Birth</th>
        </tr>
        <tr>
          <td>UNDER 12</td>
          <td>
            <input type="text" name="u12_boy_name_80mtr"  onChange={(event) => set80MeterRaceU12BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u12_boy_classes_80mtr"
              // onChange={(event) => set80MeterRaceU12BoyClasses(event.target.value)}
              maxLength="2"  // Limit input to 2 digits
  onChange={(event) => {
    const value = event.target.value;
    // Ensure the input is numeric and length is limited to 2
    if (/^\d*$/.test(value) && value.length <= 2) {
      set80MeterRaceU12BoyClasses(value);
    }
  }}
               />
          </td>
          <td>
            <input type="date" name="u12_boy_dob_80mtr"  onChange={(event) => set80MeterRaceU12BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u12_girl_name_80mtr"  onChange={(event) => set80MeterRaceU12GirlName(event.target.value)} />
          </td>
          <td>
            {/* <input type="number" name="u12_girl_classes_80mtr" 
            onChange={(event) => set80MeterRaceU12GirlClasses(event.target.value)}
             onChange={(event) => {
              const value = event.target.value;
              if (value.length <= 2) {  // Limit to 2 digits
                set80MeterRaceU12GirlClasses(value);
              }
            }} 
              /> */}

<input
  type="text"  // Change type to text
  name="u12_girl_classes_80mtr"
  maxLength="2"  // Limit input to 2 digits
  onChange={(event) => {
    const value = event.target.value;
    // Ensure the input is numeric and length is limited to 2
    if (/^\d*$/.test(value) && value.length <= 2) {
      set80MeterRaceU12GirlClasses(value);
    }
  }}
/>
          </td>
          <td>
            <input type="date" name="u12_girl_dob_80mtr"  onChange={(event) => set80MeterRaceU12GirlDob(event.target.value)} />
          </td>
        </tr>
        
        {/* Add more rows for other races similarly */}
      </tbody>
    </table>
    </div>

    <label>100 Meter Race</label>
    {/* Athletics Table */}
<div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth</th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth</th>
        </tr>
        <tr>
          <td>UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_100mtr"  onChange={(event) => set100MeterRaceU14BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_boy_classes_100mtr"  
            // onChange={(event) => set100MeterRaceU14BoyClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set100MeterRaceU14BoyClasses(value);
              }
            }}
            
            
            />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_100mtr"  onChange={(event) => set100MeterRaceU14BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_100mtr"  onChange={(event) => set100MeterRaceU14GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_100mtr" 
            //  onChange={(event) => set100MeterRaceU14GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set100MeterRaceU14GirlClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_100mtr"  onChange={(event) => set100MeterRaceU14GirlDob(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_100mtr"  onChange={(event) => set100MeterRaceU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_100mtr" 
            //  onChange={(event) => set100MeterRaceU17BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set100MeterRaceU17BoyClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_100mtr"  onChange={(event) => set100MeterRaceU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_100mtr"  onChange={(event) => set100MeterRaceU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_100mtr"  
            // onChange={(event) => set100MeterRaceU17GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set100MeterRaceU17GirlClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_100mtr"  onChange={(event) => set100MeterRaceU17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other races similarly */}
      </tbody>
    </table>
</div>
    <label>200 Meter Race</label>
    {/* Athletics Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth</th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth</th>
        </tr>
        <tr>
          <td>UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_200mtr"  onChange={(event) => set200MeterRaceU14BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_boy_classes_200mtr" 
            //  onChange={(event) => set200MeterRaceU14BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set200MeterRaceU14BoyClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_200mtr"  onChange={(event) => set200MeterRaceU14BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_200mtr"  onChange={(event) => set200MeterRaceU14GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_200mtr"
              // onChange={(event) => set200MeterRaceU14GirlClasses(event.target.value)}

              maxLength="2"  // Limit input to 2 digits
              onChange={(event) => {
                const value = event.target.value;
                // Ensure the input is numeric and length is limited to 2
                if (/^\d*$/.test(value) && value.length <= 2) {
                  set200MeterRaceU14GirlClasses(value);
                }
              }}
               />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_200mtr"  onChange={(event) => set200MeterRaceU14GirlDob(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_200mtr"  onChange={(event) => set200MeterRaceU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_200mtr" 
            //  onChange={(event) => set200MeterRaceU17BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set200MeterRaceU17BoyClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_200mtr"  onChange={(event) => set200MeterRaceU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_200mtr"  onChange={(event) => set200MeterRaceU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_200mtr" 
            //  onChange={(event) => set200MeterRaceU17GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set200MeterRaceU17GirlClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_200mtr"  onChange={(event) => set200MeterRaceU17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other races similarly */}
      </tbody>
    </table>
    </div>
    <label>400 Meter Race</label>
    {/* Athletics Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth</th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth</th>
        </tr>
        <tr>
          <td>UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_400mtr"  onChange={(event) => set400MeterRaceU14BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_boy_classes_400mtr" 
            //  onChange={(event) => set400MeterRaceU14BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set400MeterRaceU14BoyClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_400mtr"  onChange={(event) => set400MeterRaceU14BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_400mtr"  onChange={(event) => set400MeterRaceU14GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_400mtr" 
            //  onChange={(event) => set400MeterRaceU14GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set400MeterRaceU14GirlClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_400mtr"  onChange={(event) => set400MeterRaceU14GirlDob(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_400mtr"  onChange={(event) => set400MeterRaceU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_400mtr" 
            //  onChange={(event) => set400MeterRaceU17BoyClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set400MeterRaceU17BoyClasses(value);
              }
            }}

             
             />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_400mtr"  onChange={(event) => set400MeterRaceU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_400mtr"  onChange={(event) => set400MeterRaceU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_400mtr" 
            //  onChange={(event) => set400MeterRaceU17GirlClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                set400MeterRaceU17GirlClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_400mtr"  onChange={(event) => set400MeterRaceU17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other races similarly */}
      </tbody>
    </table>
</div>
    <label>Hurdle Race</label>
    {/* Athletics Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth</th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth</th>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_hurdlerace"  onChange={(event) => sethurdleraceU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_hurdlerace"
              // onChange={(event) => sethurdleraceU17BoyClasses(event.target.value)}

              maxLength="2"  // Limit input to 2 digits
              onChange={(event) => {
                const value = event.target.value;
                // Ensure the input is numeric and length is limited to 2
                if (/^\d*$/.test(value) && value.length <= 2) {
                  sethurdleraceU17BoyClasses(value);
                }
              }}
               />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_hurdlerace"  onChange={(event) => sethurdleraceU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_hurdlerace"  onChange={(event) => sethurdleraceU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_hurdlerace" 
            //  onChange={(event) => sethurdleraceU17GirlClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                sethurdleraceU17GirlClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_hurdlerace"  onChange={(event) => sethurdleraceU17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other races similarly */}
      </tbody>
    </table>
</div>
    <label>Long Jump</label>
    {/* Athletics Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth </th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth </th>
        </tr>
        <tr>
          <td>UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_longjump"  onChange={(event) => setLongJumpU14BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_boy_classes_longjump"
              // onChange={(event) => setLongJumpU14BoyClasses(event.target.value)}
              maxLength="2"  // Limit input to 2 digits
              onChange={(event) => {
                const value = event.target.value;
                // Ensure the input is numeric and length is limited to 2
                if (/^\d*$/.test(value) && value.length <= 2) {
                  setLongJumpU14BoyClasses(value);
                }
              }}
               />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_longjump"  onChange={(event) => setLongJumpU14BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_longjump"  onChange={(event) => setLongJumpU14GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_longjump" 
            //  onChange={(event) => setLongJumpU14GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setLongJumpU14GirlClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_longjump"  onChange={(event) => setLongJumpU14GirlDob(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_LongJump"  onChange={(event) => setLongJumpU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_LongJump" 
            
            // onChange={(event) => setLongJumpU17BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setLongJumpU17BoyClasses(value);
              }
            }}
            
            />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_LongJump"  onChange={(event) => setLongJumpU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_LongJump"  onChange={(event) => setLongJumpU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_LongJump" 
            //  onChange={(event) => setLongJumpU17GirlClasses(event.target.value)}

            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setLongJumpU17GirlClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_LongJump"  onChange={(event) => setLongJump17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other races similarly */}
      </tbody>
    </table>
</div>
    <div className="section-title">Relay Race 4 x 100 Meter </div>
    {/* <label>4 Players in each team</label> */}
    {/* Relay Race Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth </th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth </th>
        </tr>
        <tr>
          <td>UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_relay_race_1"  onChange={(event) => setRelayRaceU14BoyName1(event.target.value)} placeholder='1' /><br/>
            <input type="text" name="u14_boy_name_relay_race_2"  onChange={(event) => setRelayRaceU14BoyName2(event.target.value)} placeholder='2'  />
            <input type="text" name="u14_boy_name_relay_race_3"  onChange={(event) => setRelayRaceU14BoyName3(event.target.value)} placeholder='3'  />
            <input type="text" name="u14_boy_name_relay_race_4"  onChange={(event) => setRelayRaceU14BoyName4(event.target.value)} placeholder='4'  />
          </td>
          
          <td>
            <input type="text" name="u14_boy_classes_relay_race_1" 
            //  onChange={(event) => setRelayRaceU14BoyClasses1(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU14BoyClasses1(value);
              }
            }}
              />
            <input type="text" name="u14_boy_classes_relay_race_2" 
            //  onChange={(event) => setRelayRaceU14BoyClasses2(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU14BoyClasses2(value);
              }
            }}
              />
            <input type="text" name="u14_boy_classes_relay_race_3" 
            //  onChange={(event) => setRelayRaceU14BoyClasses3(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU14BoyClasses3(value);
              }
            }}
             />
            <input type="text" name="u14_boy_classes_relay_race_4" 
            //  onChange={(event) => setRelayRaceU14BoyClasses4(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU14BoyClasses4(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_relay_race_1"  onChange={(event) => setRelayRaceU14BoyDob1(event.target.value)} />
            <input type="date" name="u14_boy_dob_relay_race_2"  onChange={(event) => setRelayRaceU14BoyDob2(event.target.value)} />
            <input type="date" name="u14_boy_dob_relay_race_3"  onChange={(event) => setRelayRaceU14BoyDob3(event.target.value)} />
            <input type="date" name="u14_boy_dob_relay_race_4"  onChange={(event) => setRelayRaceU14BoyDob4(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_relay_race_1"  onChange={(event) => setRelayRaceU14GirlName1(event.target.value)} />
            <input type="text" name="u14_girl_name_relay_race_2"  onChange={(event) => setRelayRaceU14GirlName2(event.target.value)} />
            <input type="text" name="u14_girl_name_relay_race_3"  onChange={(event) => setRelayRaceU14GirlName3(event.target.value)} />
            <input type="text" name="u14_girl_name_relay_race_4"  onChange={(event) => setRelayRaceU14GirlName4(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_relay_race_1" 
            //  onChange={(event) => setRelayRaceU14GirlClasses1(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU14GirlClasses1(value);
              }
            }}
              />
            <input type="text" name="u14_girl_classes_relay_race_2" 
            //  onChange={(event) => setRelayRaceU14GirlClasses2(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU14GirlClasses2(value);
              }
            }}
             />
            <input type="text" name="u14_girl_classes_relay_race_3" 
            //  onChange={(event) => setRelayRaceU14GirlClasses3(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU14GirlClasses3(value);
              }
            }} 
             />
            <input type="text" name="u14_girl_classes_relay_race_4" 
            //  onChange={(event) => setRelayRaceU14GirlClasses4(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU14GirlClasses4(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_relay_race_1"  onChange={(event) => setRelayRaceU14GirlDob1(event.target.value)} />
            <input type="date" name="u14_girl_dob_relay_race_2"  onChange={(event) => setRelayRaceU14GirlDob2(event.target.value)} />
            <input type="date" name="u14_girl_dob_relay_race_3"  onChange={(event) => setRelayRaceU14GirlDob3(event.target.value)} />
            <input type="date" name="u14_girl_dob_relay_race_4"  onChange={(event) => setRelayRaceU14GirlDob4(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_relay_race_1"  onChange={(event) => setRelayRaceU17BoyName1(event.target.value)} placeholder='1'  />
            <input type="text" name="u17_boy_name_relay_race_2"  onChange={(event) => setRelayRaceU17BoyName2(event.target.value)} placeholder='2'  />
            <input type="text" name="u17_boy_name_relay_race_3"  onChange={(event) => setRelayRaceU17BoyName3(event.target.value)} placeholder='3'  />
            <input type="text" name="u17_boy_name_relay_race_4"  onChange={(event) => setRelayRaceU17BoyName4(event.target.value)} placeholder='4'  />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_relay_race_1" 
            //  onChange={(event) => setRelayRaceU17BoyClasses1(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU17BoyClasses1(value);
              }
            }}
              />
            <input type="text" name="u17_boy_classes_relay_race_2" 
            //  onChange={(event) => setRelayRaceU17BoyClasses2(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU17BoyClasses2(value);
              }
            }}
              />
            <input type="text" name="u17_boy_classes_relay_race_3" 
            //  onChange={(event) => setRelayRaceU17BoyClasses3(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU17BoyClasses3(value);
              }
            }}
              />
            <input type="text" name="u17_boy_classes_relay_race_4" 
            //  onChange={(event) => setRelayRaceU17BoyClasses4(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU17BoyClasses4(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_relay_race_1"  onChange={(event) => setRelayRaceU17BoyDob1(event.target.value)} />
            <input type="date" name="u17_boy_dob_relay_race_2"  onChange={(event) => setRelayRaceU17BoyDob2(event.target.value)} />
            <input type="date" name="u17_boy_dob_relay_race_3"  onChange={(event) => setRelayRaceU17BoyDob3(event.target.value)} />
            <input type="date" name="u17_boy_dob_relay_race_4"  onChange={(event) => setRelayRaceU17BoyDob4(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_relay_race_1"  onChange={(event) => setRelayRaceU17GirlName1(event.target.value)} />
            <input type="text" name="u17_girl_name_relay_race_2"  onChange={(event) => setRelayRaceU17GirlName2(event.target.value)} />
            <input type="text" name="u17_girl_name_relay_race_3"  onChange={(event) => setRelayRaceU17GirlName3(event.target.value)} />
            <input type="text" name="u17_girl_name_relay_race_4"  onChange={(event) => setRelayRaceU17GirlName4(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_relay_race_1" 
            //  onChange={(event) => setRelayRaceU17GirlClasses1(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU17GirlClasses1(value);
              }
            }}
             />
            <input type="text" name="u17_girl_classes_relay_race_2"
              // onChange={(event) => setRelayRaceU17GirlClasses2(event.target.value)}
              maxLength="2"  // Limit input to 2 digits
              onChange={(event) => {
                const value = event.target.value;
                // Ensure the input is numeric and length is limited to 2
                if (/^\d*$/.test(value) && value.length <= 2) {
                  setRelayRaceU17GirlClasses2(value);
                }
              }}
               />
            <input type="text" name="u17_girl_classes_relay_race_3"
              // onChange={(event) => setRelayRaceU17GirlClasses3(event.target.value)}
              maxLength="2"  // Limit input to 2 digits
              onChange={(event) => {
                const value = event.target.value;
                // Ensure the input is numeric and length is limited to 2
                if (/^\d*$/.test(value) && value.length <= 2) {
                  setRelayRaceU17GirlClasses3(value);
                }
              }}
               />
            <input type="text" name="u17_girl_classes_relay_race_4" 
            //  onChange={(event) => setRelayRaceU17GirlClasses4(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setRelayRaceU17GirlClasses4(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_relay_race_1"  onChange={(event) => setRelayRaceU17GirlDob1(event.target.value)} />
            <input type="date" name="u17_girl_dob_relay_race_2"  onChange={(event) => setRelayRaceU17GirlDob2(event.target.value)} />
            <input type="date" name="u17_girl_dob_relay_race_3"  onChange={(event) => setRelayRaceU17GirlDob3(event.target.value)} />
            <input type="date" name="u17_girl_dob_relay_race_4"  onChange={(event) => setRelayRaceU17GirlDob4(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other team members */}
      </tbody>
    </table>
</div>
    <label>Short Put</label>
    {/* Relay Race Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th >Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth </th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth </th>
        </tr>
        <tr>
          <td >UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_short_put"  onChange={(event) => setShortPutU14BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_boy_classes_short_put" 
            //  onChange={(event) => setShortPutU14BoyClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setShortPutU14BoyClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_short_put"  onChange={(event) => setShortPutU14BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_short_put"  onChange={(event) => setShortPutU14GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_short_put" 
            //  onChange={(event) => setShortPutU14GirlClasses(event.target.value)}
             maxLength="2"  // Limit input to 2 digits
             onChange={(event) => {
               const value = event.target.value;
               // Ensure the input is numeric and length is limited to 2
               if (/^\d*$/.test(value) && value.length <= 2) {
                setShortPutU14GirlClasses(value);
               }
             }}
              />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_short_put"  onChange={(event) => setShortPutU14GirlDob(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_short_put"  onChange={(event) => setShortPutU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_short_put" 
            
            // onChange={(event) => setShortPutU17BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setShortPutU17BoyClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_short_put"  onChange={(event) => setShortPutU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_short_put"  onChange={(event) => setShortPutU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_short_put" 
            //  onChange={(event) => setShortPutU17GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setShortPutU17GirlClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_short_put"  onChange={(event) => setShortPut17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other team members */}
      </tbody>
    </table>
    </div>

    <label>Badminton (Singles)</label>
    {/* Relay Race Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth</th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth </th>
        </tr>
        <tr>
          <td>UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_short_put"  onChange={(event) => setBadmintonU14BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_boy_classes_short_put" 
            //  onChange={(event) => setBadmintonU14BoyClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setBadmintonU14BoyClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_short_put"  onChange={(event) => setBadmintonU14BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_short_put"  onChange={(event) => setBadmintonU14GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_short_put" 
            //  onChange={(event) => setBadmintonU14GirlClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setBadmintonU14GirlClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_short_put"  onChange={(event) => setBadmintonU14GirlDob(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_badminton"  onChange={(event) => setBadmintonU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_badminton" 
            //  onChange={(event) => setBadmintonU17BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setBadmintonU17BoyClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_badminton"  onChange={(event) => setBadmintonU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_badminton"  onChange={(event) => setBadmintonU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_badminton" 
            //  onChange={(event) => setBadmintonU17GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setBadmintonU17GirlClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_badminton"  onChange={(event) => setBadmintonU17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other team members */}
      </tbody>
    </table>
</div>
    <label>Chess (Singles)</label>
    {/* Relay Race Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys )</th>
          <th>Class</th>
          <th>Date of Birth </th>
          <th>Name (Girls )</th>
          <th>Class</th>
          <th>Date of Birth </th>
        </tr>
        <tr>
          <td>UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_chess"  onChange={(event) => setChessU14BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_boy_classes_chess" 
            //  onChange={(event) => setChessU14BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setChessU14BoyClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_chess"  onChange={(event) => setChessU14BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_chess"  onChange={(event) => setChessU14GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_chess" 
            // onChange={(event) => setChessU14GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setChessU14GirlClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_chess"  onChange={(event) => setChess14GirlDob(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_chess"  onChange={(event) => setChessU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_chess" 
            //  onChange={(event) => setChessU17BoyClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setChessU17BoyClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_chess"  onChange={(event) => setChessU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_chess"  onChange={(event) => setChessU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_chess" 
            //  onChange={(event) => setChessU17GirlClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setChessU17GirlClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_chess"  onChange={(event) => setChessU17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other team members */}
      </tbody>
    </table>
    </div>

    <label>Table Tennis (Singles)</label>
    {/* Relay Race Table */}
    <div class="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Event</th>
          <th>Name (Boys)</th>
          <th>Class</th>
          <th>Date of Birth </th>
          <th>Name (Girls)</th>
          <th>Class</th>
          <th>Date of Birth </th>
        </tr>
        <tr>
          <td>UNDER 14</td>
          <td>
            <input type="text" name="u14_boy_name_table_tenis"  onChange={(event) => setTabelTenisU14BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_boy_classes_table_tenis" 
            //  onChange={(event) => setTabelTenisU14BoyClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setTabelTenisU14BoyClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u14_boy_dob_table_tenis"  onChange={(event) => setTabelTenisU14BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_name_table_tenis"  onChange={(event) => setTabelTenisU14GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u14_girl_classes_table_tenis" 
            //  onChange={(event) => setTabelTenisU14GirlClasses(event.target.value)} 
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setTabelTenisU14GirlClasses(value);
              }
            }}
             />
          </td>
          <td>
            <input type="date" name="u14_girl_dob_table_tenis"  onChange={(event) => setTabelTenisU14GirlDob(event.target.value)} />
          </td>
        </tr>
        <tr>
          <td>UNDER 17</td>
          <td>
            <input type="text" name="u17_boy_name_table_tenis"  onChange={(event) => setTabelTenisU17BoyName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_boy_classes_table_tenis" 
            //  onChange={(event) => setTabelTenisU17BoyClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setTabelTenisU17BoyClasses(value);
              }
            }}
              />
          </td>
          <td>
            <input type="date" name="u17_boy_dob_table_tenis"  onChange={(event) => setTabelTenisU17BoyDob(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_name_table_tenis"  onChange={(event) => setTabelTenisU17GirlName(event.target.value)} />
          </td>
          <td>
            <input type="text" name="u17_girl_classes_table_tenis" 
            //  onChange={(event) => setTabelTenisU17GirlClasses(event.target.value)}
            maxLength="2"  // Limit input to 2 digits
            onChange={(event) => {
              const value = event.target.value;
              // Ensure the input is numeric and length is limited to 2
              if (/^\d*$/.test(value) && value.length <= 2) {
                setTabelTenisU17GirlClasses(value);
              }
            }}

              />
          </td>
          <td>
            <input type="date" name="u17_girl_dob_table_tenis"  onChange={(event) => setTabelTenisU17GirlDob(event.target.value)} />
          </td>
        </tr>
        {/* Add more rows for other team members */}
      </tbody>
    </table>
    </div>

    <div className="section-title">Declaration</div>
             <div className="declaration">
                <label>
                    <input 
                        type="checkbox" 
                        name="declaration" 
                        // required 
                        checked={isDeclarationChecked} 
                        // onChange={() => setDeclarationChecked(!isDeclarationChecked)} 
                        onChange={(event) => setDeclarationChecked(event.target.value)}
                        style={{
                          borderColor: !isValid && agreement.trim() === "" ? "red" : "",
                        }}
                    />
                  
                    All the above information is true as per school records and we hereby accept that no change of participant's name shall be accepted after submitted this form.
                    {!isValid && agreement2.trim() === "" && (
                        <p style={{ color: "red" }}>This field is required</p>
                      )}
                </label>
                <label>
                    <input 
                        type="checkbox" 
                        name="agreement1" 
                      //  required 
                        checked={isAgreementChecked1} 
                        // onChange={() => setAgreementChecked1(!isAgreementChecked1)} 
                        onChange={(event) => setAgreementChecked1(event.target.value)}
                        style={{
                          borderColor: !isValid && agreement1.trim() === "" ? "red" : "",
                        }}
                    />
                    I agree that on the spot entry/change will not be permitted.
                    {!isValid && agreement1.trim() === "" && (
                        <p style={{ color: "red" }}>This field is required</p>
                      )}
                </label>
                <label>
                    <input 
                        type="checkbox" 
                        name="agreement2" 
                        // required 
                        checked={isAgreementChecked2} 
                        // onChange={() => setAgreementChecked2(!isAgreementChecked2)} 
                        onChange={(event) => setAgreementChecked2(event.target.value)}
                        style={{
                          borderColor: !isValid && agreement2.trim() === "" ? "red" : "",
                        }}
                      />
                     
                    In case of age difference as on 9th Nov 2024 will be considered as disqualified.
                    {!isValid && agreement2.trim() === "" && (
                        <p style={{ color: "red" }}>This field is required</p>
                      )}
                </label>
            </div> 
    <input type="submit" defaultValue="Submit" />
      </form>
    </div>
    </section>
  );
}

export default FormComponent;
