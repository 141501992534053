import React, { useState,useEffect } from 'react';

import coin1 from '../../images/coin-1.webp';
import coin2 from '../../images/coin-2.webp';
import coin3 from '../../images/coin-3.webp';
import coin4 from '../../images/coin-4.webp';
import WINNER_C from '../../images/WINNER_C.PNG';
import IMAGEADE from '../../images/WINNER_C.PNG';
import aboutbitbetioimage from '../../images/about-bitbetio-image.webp';
import morefeaturesimage from '../../images/more-features-image.webp';
import COUNTER1 from '../../images/counter-icon-1.webp';
import COUNTER2 from '../../images/counter-icon-2.webp';
import RegistrationForm from '../../images/Registration Form Block.jpg';
import RuleBook from '../../images/Rule Book Block.jpg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
const pdfUrl = 'https://sttar.in/ecce/Athletica-Rule-Book.pdf'

const HomeAboutPage = () => {

  const validateEmail = (email) => {
    const regex = /.+@jaipuria\.(school|edu\.in)$/;
    return regex.test(email);
  };
  
  // const [email, setPrincipalEmail] = useState('');

  const DownloadFile = () => {
    useEffect(() => {
      // Trigger the download when the component mounts
      window.location.href = 'https://sttar.in/ecce/Athletica-Rule-Book.pdf';
    }, []);
  
    return (
      <div>
        <h2>Your download will start shortly...</h2>
        <p>If the download does not start, <a href="https://sttar.in/ecce/Athletica-Rule-Book.pdf">click here</a> to download manually.</p>
      </div>
    );
  };








  const [formData, setFormData] = useState({ name: '', email: '', mobile_number: '' });
  const [successMessage, setSuccessMessage] = useState(''); // State for success message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter an email address ending with @jaipuria.school or @jaipuria.edu.in.',
      });
      return;
    }
    

    try {
      const response = await axios.post('https://testingnew.sttar.in/api/rule-book-download', formData);
      console.log('Response:', response.data); // Log the response data

      if (response.status === 201) {
        // Set success message from response
        setSuccessMessage(response.data.message); // Assuming message is in response.data
        //window.location.href = 'https://sttar.in/ecce/Athletica-Rule-Book.pdf';
        window.open('https://sttar.in/ecce/Athletica-Rule-Book.pdf', '_blank');
      
        // Redirect to www.google.com if needed
        if (response.data.pdfUrl) {
         
        } else {
          console.error('PDF URL not found in response data.');
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSuccessMessage('Error submitting form. Please try again.'); // Optional: set an error message
    }
  };

  return (
    <>
      <section className="how-it-works">
        <div className="row justify-content-center" style={{ marginLeft: "10px" }}>
          <div className="col-lg-8">
            <div className="section-header text-center">
              <p style={{ color: "#000", fontSize: "14px" }}><br />
              Seth Anandram Jaipuria Schools are dedicated to offering students a wide range of opportunities to learn, grow, and excel. Athletica, our flagship inter-school sports event, raises both preparation and performance to new heights of excellence, providing students with a unique platform to showcase their talents and sportsmanship.
The inaugural edition of the Athletica Inter-Jaipuria School Sports Championship will be held in Lucknow, gathering over 750+ students from all our 21 Jaipuria schools across India. The event to be hosted at the Guru Govind Singh Sports College, Kursi Road, Lucknow, is expected to draw more than 1,000 attendees, including students, parents, teachers, and special guests. This prestigious championship is projected to reach an audience of over 10,000, amplifying the recognition of our students' achievements and the event's spirit.
Athletica 2024 will feature a variety of sports competitions, promoting healthy competition and camaraderie among students. Participation in sports not only enhances physical fitness and mental well-being but also instills essential life skills such as teamwork, discipline, and resilience.
At Seth Anandram Jaipuria Schools, we are committed to fostering an inclusive, co-educational environment. In line with this philosophy, Athletica will include mixed teams of boys and girls, reflecting our dedication to equality and collaboration.
We eagerly anticipate the remarkable performances and the spirit of unity that Athletica 2024 will bring.
              
              </p>
            </div>
          </div>
          <div className="col-lg-4" style={{ marginTop: "10px" }}>
            <Link to="/register" type="button">
              <img alt="icon" src={RegistrationForm} height={20} decoding="async" loading="lazy" style={{ color: "transparent", maxWidth: "66%" }} />
            </Link>
            <Link data-bs-toggle="modal" data-bs-target="#downloadModal" type="button" style={{ marginTop: "10px" }}>
              <img className='RuleBook' alt="icon" src={RuleBook} height={20} decoding="async" loading="lazy" style={{ color: "transparent", maxWidth: "66%" }} />
            </Link>
          </div>
        </div>

        {/* Modal */}
        <div className="modal fade" id="downloadModal" tabIndex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="downloadModalLabel" style={{ color: "#000" }}>Download Rule Book with your official credentials</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name:</label>
                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mobile_number" className="form-label">Mobile Number:</label>
                    <input type="text" className="form-control" id="mobile_number" name="mobile_number" value={formData.mobile_number} onChange={handleChange} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input type="email"  id="email" name="email" value={formData.email} onChange={handleChange}   className={validateEmail(formData.email)  ? '' : 'is-invalid'}
                  />
            {validateEmail(formData.email) ? null : (
              <div className="invalid-feedback">
  Please enter a valid email address ending with @jaipuria.school or @jaipuria.edu.in
              </div>
            )}
                  </div>
                  <button type="submit" className="cmn-btn reg">Submit</button>
                </form>
                {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>} {/* Display success message */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAboutPage;
