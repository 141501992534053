import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.png'; // Adjust the path as necessary

const Footer = () => {
    return (
      <>
<div className="i-anchor" id="footer"></div>
    <div className="contact-container">
      <h4>Contact Us</h4>
      <p>
        <strong>Address:</strong> 196, Sector 63 Rd, D Block, Sector 63, Noida, Uttar Pradesh 201307
      </p>
      <p>
        <strong>E-mail:</strong> <a href="mailto:athletica@jaipuria.edu.in">athletica@jaipuria.edu.in</a>
      </p>
      <p>
        <strong>Contact:</strong> +91-9911254411
      </p>
    </div>



      </>
    );
};

export default Footer;