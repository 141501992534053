import React, { useRef, useEffect } from 'react';
import './slider.css'; // Add your styles here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import pic1 from '../../images/Pic 1.jpg';
import pic2 from '../../images/Pic 2.jpg';
import pic3 from '../../images/Pic 3.jpg';
import pic4 from '../../images/Pic 4.jpg';
const Slider = () => {
  const sliderRef = useRef(null);
  const slideIntervalRef = useRef(null); // Reference to the interval
  const totalImages = 8; // Update this to the number of images

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= sliderRef.current.clientWidth; // Scroll left by full width
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += sliderRef.current.clientWidth; // Scroll right by full width
    }
  };

  const autoSlide = () => {
    if (sliderRef.current) {
      // Scroll right by full width
      sliderRef.current.scrollLeft += sliderRef.current.clientWidth;

      // Reset to the start if at the end
      if (sliderRef.current.scrollLeft >= sliderRef.current.scrollWidth - sliderRef.current.clientWidth) {
        sliderRef.current.scrollLeft = 0; // Jump to the start
      }
    }
  };

  useEffect(() => {
    // Set interval for auto sliding
    slideIntervalRef.current = setInterval(autoSlide, 3000); // Change slide every 3 seconds

    // Clear interval on component unmount
    return () => {
      clearInterval(slideIntervalRef.current);
    };
  }, []);

  const images = [pic1, pic2, pic3, pic4]; // Use imported images


//   const images = [
//     "https://images.unsplash.com/photo-1574482620811-1aa16ffe3c82?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=20",
//     "https://images.unsplash.com/photo-1574451311232-cb647e9d71f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=20",
//     "https://images.unsplash.com/photo-1574449423472-4bc6a3d2473d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=20",
//     "https://images.unsplash.com/photo-1574459472673-09bbda49102a?ixlib=rb-1.2.1&auto=format&fit=crop&w=928&q=20",
//     "https://images.unsplash.com/photo-1479981280584-037818c1297d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=20",
//     "https://images.unsplash.com/photo-1511593358241-7eea1f3c84e5?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=20",
//     "https://images.unsplash.com/photo-1560259324-12a044e67c34?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=20",
//     "https://images.unsplash.com/photo-1532787799187-93655e51d472?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=20"
//   ];

  return (
    <>
      <div className="i-anchor" id="home"></div>
    
   
    <section className="banner-section">
      {/* <h3>Simple Slider</h3> */}
      <div id="slider-container" className="slider" ref={sliderRef}>
        {images.map((src, index) => (
          <div className="slide" key={index}>
            <img src={src} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <div onClick={handlePrev} className="control-prev-btn">
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <div onClick={handleNext} className="control-next-btn">
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
    </section>
    </>
  );
};

export default Slider;
