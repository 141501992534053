import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ApiTable = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('https://testingnew.sttar.in/api/athletica')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('Error fetching the data', error);
            });
    }, []);



 // Function to export the table as PDF
 const exportToPDF = () => {
    const input = document.getElementById('table-to-export');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape'); // Use 'portrait' or 'landscape'
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('table_data.pdf');
    });
  };






    return (
        <>
        <div className='inner-page'> 
        <div className='container'> 
        <div className='row'> 
        <h4>Table</h4>
        <div className="table-container">
        <div className="table-scroll" id="table-to-export">
        <table className='content-table mytable' >
            <thead>
                <tr>
            <th>ID</th>
            <th>School Name</th>
            <th>Campus</th>
            <th>Principal Name</th>
            <th>Principal Email</th>
            <th>Principal Mobile</th>
            <th>Sports Incharge</th>
            <th>Incharge Mobile</th>
            <th>Male Teacher</th>
            <th>Male Teacher Mobile</th>
            <th>Female Teacher</th>
            <th>Female Teacher Mobile</th>

            <th>U12 Boy Name (80mtr)</th>
            <th>U12 Boy Classes (80mtr)</th>
            <th>U12 Boy DOB (80mtr)</th>
            <th>U12 Girl Name (80mtr)</th>
            <th>U12 Girl Classes (80mtr)</th>
            <th>U12 Girl DOB (80mtr)</th>

            <th>U14 Boy Name (100mtr)</th>
<th>U14 Boy Classes (100mtr)</th>
<th>U14 Girl Classes (100mtr)</th>
<th>U14 Boy DOB (100mtr)</th>
<th>U14 Girl Name (100mtr)</th>
<th>U14 Girl DOB (100mtr)</th>

<th>U17 Boy Name (100mtr)</th>
<th>U17 Boy Classes (100mtr)</th>
<th>U17 Boy DOB (100mtr)</th>
<th>U17 Girl Name (100mtr)</th>
<th>U17 Girl Classes (100mtr)</th>
<th>U17 Girl DOB (100mtr)</th>

<th>U14 Boy Name (200mtr)</th>
<th>U14 Boy Classes (200mtr)</th>
<th>U14 Girl Classes (200mtr)</th>
<th>U14 Boy DOB (200mtr)</th>
<th>U14 Girl Name (200mtr)</th>
<th>U14 Girl DOB (200mtr)</th>

<th>U17 Boy Name (200mtr)</th>
<th>U17 Boy Classes (200mtr)</th>
<th>U17 Boy DOB (200mtr)</th>
<th>U17 Girl Name (200mtr)</th>
<th>U17 Girl Classes (200mtr)</th>
<th>U17 Girl DOB (200mtr)</th>

<th>U14 Boy Name (400mtr)</th>
<th>U14 Boy Classes (400mtr)</th>
<th>U14 Girl Classes (400mtr)</th>
<th>U14 Boy DOB (400mtr)</th>
<th>U14 Girl Name (400mtr)</th>
<th>U14 Girl DOB (400mtr)</th>

<th>U17 Boy Name (400mtr)</th>
<th>U17 Boy Classes (400mtr)</th>
<th>U17 Boy DOB (400mtr)</th>
<th>U17 Girl Name (400mtr)</th>
<th>U17 Girl Classes (400mtr)</th>
<th>U17 Girl DOB (400mtr)</th>
<th>U17 Boy Name (Hurdle Race)</th>
<th>U17 Boy Classes (Hurdle Race)</th>
<th>U17 Boy DOB (Hurdle Race)</th>
<th>U17 Girl Name (Hurdle Race)</th>
<th>U17 Girl Classes (Hurdle Race)</th>
<th>U17 Girl DOB (Hurdle Race)</th>

<th>U14 Boy Name (Long Jump)</th>
<th>U14 Boy Classes (Long Jump)</th>
<th>U14 Girl Classes (Long Jump)</th>
<th>U14 Boy DOB (Long Jump)</th>
<th>U14 Girl Name (Long Jump)</th>
<th>U14 Girl DOB (Long Jump)</th>

<th>U17 Boy Name (Long Jump)</th>
<th>U17 Boy Classes (Long Jump)</th>
<th>U17 Boy DOB (Long Jump)</th>
<th>U17 Girl Name (Long Jump)</th>
<th>U17 Girl Classes (Long Jump)</th>
<th>U17 Girl DOB (Long Jump)</th>

<th>U14 Boy Name (Relay Race 1)</th>
<th>U14 Boy Classes (Relay Race 1)</th>
<th>U14 Girl Classes (Relay Race 1)</th>
<th>U14 Boy DOB (Relay Race 1)</th>
<th>U14 Girl Name (Relay Race 1)</th>
<th>U14 Girl DOB (Relay Race 1)</th>

<th>U17 Boy Name (Relay Race 1)</th>
<th>U17 Boy Classes (Relay Race 1)</th>
<th>U17 Boy DOB (Relay Race 1)</th>
<th>U17 Girl Name (Relay Race 1)</th>
<th>U17 Girl Classes (Relay Race 1)</th>
<th>U17 Girl DOB (Relay Race 1)</th>

<th>U14 Boy Name (Relay Race 2)</th>
<th>U14 Boy Classes (Relay Race 2)</th>
<th>U14 Girl Classes (Relay Race 2)</th>
<th>U14 Boy DOB (Relay Race 2)</th>
<th>U14 Girl Name (Relay Race 2)</th>
<th>U14 Girl DOB (Relay Race 2)</th>

<th>U17 Boy Name (Relay Race 2)</th>
<th>U17 Boy Classes (Relay Race 2)</th>
<th>U17 Boy DOB (Relay Race 2)</th>
<th>U17 Girl Name (Relay Race 2)</th>
<th>U17 Girl Classes (Relay Race 2)</th>
<th>U17 Girl DOB (Relay Race 2)</th>

<th>U14 Boy Name (Relay Race 3)</th>
<th>U14 Boy Classes (Relay Race 3)</th>
<th>U14 Girl Classes (Relay Race 3)</th>
<th>U14 Boy DOB (Relay Race 3)</th>
<th>U14 Girl Name (Relay Race 3)</th>
<th>U14 Girl DOB (Relay Race 3)</th>

<th>U17 Boy Name (Relay Race 3)</th>
<th>U17 Boy Classes (Relay Race 3)</th>
<th>U17 Boy DOB (Relay Race 3)</th>
<th>U17 Girl Name (Relay Race 3)</th>
<th>U17 Girl Classes (Relay Race 3)</th>
<th>U17 Girl DOB (Relay Race 3)</th>

<th>U14 Boy Name (Relay Race 4)</th>
<th>U14 Boy Classes (Relay Race 4)</th>
<th>U14 Girl Classes (Relay Race 4)</th>
<th>U14 Boy DOB (Relay Race 4)</th>
<th>U14 Girl Name (Relay Race 4)</th>
<th>U14 Girl DOB (Relay Race 4)</th>

<th>U17 Boy Name (Relay Race 4)</th>
<th>U17 Boy Classes (Relay Race 4)</th>
<th>U17 Boy DOB (Relay Race 4)</th>
<th>U17 Girl Name (Relay Race 4)</th>
<th>U17 Girl Classes (Relay Race 4)</th>
<th>U17 Girl DOB (Relay Race 4)</th>

<th>U14 Boy Name (Shot Put)</th>
<th>U14 Boy Classes (Shot Put)</th>
<th>U14 Girl Classes (Shot Put)</th>
<th>U14 Boy DOB (Shot Put)</th>
<th>U14 Girl Name (Shot Put)</th>
<th>U14 Girl DOB (Shot Put)</th>

<th>U17 Boy Name (Shot Put)</th>
<th>U17 Boy Classes (Shot Put)</th>
<th>U17 Boy DOB (Shot Put)</th>
<th>U17 Girl Name (Shot Put)</th>
<th>U17 Girl Classes (Shot Put)</th>
<th>U17 Girl DOB (Shot Put)</th>

<th>U14 Boy Name (Badminton)</th>
<th>U14 Boy Classes (Badminton)</th>
<th>U14 Girl Classes (Badminton)</th>
<th>U14 Boy DOB (Badminton)</th>
<th>U14 Girl Name (Badminton)</th>
<th>U14 Girl DOB (Badminton)</th>

<th>U17 Boy Name (Badminton)</th>
<th>U17 Boy Classes (Badminton)</th>
<th>U17 Boy DOB (Badminton)</th>
<th>U17 Girl Name (Badminton)</th>
<th>U17 Girl Classes (Badminton)</th>
<th>U17 Girl DOB (Badminton)</th>

<th>U14 Boy Name (Chess)</th>
<th>U14 Boy Classes (Chess)</th>
<th>U14 Girl Classes (Chess)</th>
<th>U14 Boy DOB (Chess)</th>
<th>U14 Girl Name (Chess)</th>
<th>U14 Girl DOB (Chess)</th>

<th>U17 Boy Name (Chess)</th>
<th>U17 Boy Classes (Chess)</th>
<th>U17 Boy DOB (Chess)</th>
<th>U17 Girl Name (Chess)</th>
<th>U17 Girl Classes (Chess)</th>
<th>U17 Girl DOB (Chess)</th>

<th>U14 Boy Name (Table Tennis)</th>
<th>U14 Boy Classes (Table Tennis)</th>
<th>U14 Girl Classes (Table Tennis)</th>
<th>U14 Boy DOB (Table Tennis)</th>
<th>U14 Girl Name (Table Tennis)</th>
<th>U14 Girl DOB (Table Tennis)</th>

<th>U17 Boy Name (Table Tennis)</th>
<th>U17 Boy Classes (Table Tennis)</th>
<th>U17 Boy DOB (Table Tennis)</th>
<th>U17 Girl Name (Table Tennis)</th>
<th>U17 Girl Classes (Table Tennis)</th>
<th>U17 Girl DOB (Table Tennis)</th>

<th>Created At</th>
<th>Updated At</th>

                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.school_name}</td>
              <td>{item.campus}</td>
              <td>{item.principal_email}</td>
              <td>{item.principal_name}</td>
              <td>{item.principal_mobile}</td>
              <td>{item.sports_incharge}</td>
              <td>{item.incharge_mobile}</td>
              <td>{item.male_teacher}</td>
              <td>{item.male_teacher_mobile}</td>
              <td>{item.female_teacher}</td>
              <td>{item.female_teacher_mobile}</td>

              <td>{item.u12_boy_name_80mtr || "N/A"}</td>
              <td>{item.u12_boy_classes_80mtr || "N/A"}</td>
              <td>{item.u12_boy_dob_80mtr || "N/A"}</td>

              <td>{item.u12_girl_name_80mtr}</td>
              <td>{item.u12_girl_classes_80mtr}</td>
              <td>{item.u12_girl_dob_80mtr}</td>



             
         

               
               <td>{item.u14_boy_name_100mtr}</td>
        <td>{item.u14_boy_classes_100mtr}</td>
        <td>{item.u14_girl_classes_100mtr}</td>
        <td>{item.u14_boy_dob_100mtr}</td>
        <td>{item.u14_girl_name_100mtr}</td>
        <td>{item.u14_girl_dob_100mtr}</td>
        <td>{item.u17_boy_name_100mtr}</td>
        <td>{item.u17_boy_classes_100mtr}</td>
        <td>{item.u17_boy_dob_100mtr}</td>
         <td>{item.u17_girl_name_100mtr}</td>
         <td>{item.u17_girl_classes_100mtr}</td>
         <td>{item.u17_girl_dob_100mtr}</td>
         <td>{item.u14_boy_name_200mtr}</td>
         <td>{item.u14_boy_classes_200mtr}</td>
         <td>{item.u14_girl_classes_200mtr}</td>
         <td>{item.u14_boy_dob_200mtr}</td>
         <td>{item.u14_girl_name_200mtr}</td>
         <td>{item.u14_girl_dob_200mtr}</td>
         <td>{item.u17_boy_name_200mtr}</td>
         <td>{item.u17_boy_classes_200mtr}</td>
         <td>{item.u17_boy_dob_200mtr}</td>
         <td>{item.u17_girl_name_200mtr}</td>
         <td>{item.u17_girl_classes_200mtr}</td>
         <td>{item.u17_girl_dob_200mtr}</td>
         <td>{item.u14_boy_name_400mtr}</td>
         <td>{item.u14_boy_classes_400mtr}</td>
         <td>{item.u14_girl_classes_400mtr}</td>
         <td>{item.u14_boy_dob_400mtr}</td>
         <td>{item.u14_girl_name_400mtr}</td>
         <td>{item.u14_girl_dob_400mtr}</td>
         <td>{item.u17_boy_name_400mtr}</td>
         <td>{item.u17_boy_classes_400mtr}</td>
         <td>{item.u17_boy_dob_400mtr}</td>
         <td>{item.u17_girl_name_400mtr}</td>
         <td>{item.u17_girl_classes_400mtr}</td>
         <td>{item.u17_girl_dob_400mtr}</td>
         <td>{item.u17_boy_name_hurdlerace}</td>
         <td>{item.u17_boy_classes_hurdlerace}</td>
         <td>{item.u17_boy_dob_hurdlerace}</td>
         <td>{item.u17_girl_name_hurdlerace}</td>
         <td>{item.u17_girl_classes_hurdlerace}</td>
         <td>{item.u17_girl_dob_hurdlerace}</td>
         <td>{item.u14_boy_name_longjump}</td>
         <td>{item.u14_boy_classes_longjump}</td>
         <td>{item.u14_girl_classes_longjump}</td>
         <td>{item.u14_boy_dob_longjump}</td>
         <td>{item.u14_girl_name_longjump}</td>
         <td>{item.u14_girl_dob_longjump}</td>
         <td>{item.u17_boy_name_longjump}</td>
         <td>{item.u17_boy_classes_longjump}</td>
         <td>{item.u17_boy_dob_longjump}</td>
         <td>{item.u17_girl_name_longjump}</td>
         <td>{item.u17_girl_classes_longjump}</td>
         <td>{item.u17_girl_dob_longjump}</td>
         <td>{item.u14_boy_name_relay_race_1}</td>
         <td>{item.u14_boy_classes_relay_race_1}</td>
         <td>{item.u14_girl_classes_relay_race_1}</td>
         <td>{item.u14_boy_dob_relay_race_1}</td>
         <td>{item.u14_girl_name_relay_race_1}</td>
         <td>{item.u14_girl_dob_relay_race_1}</td>
         <td>{item.u17_boy_name_relay_race_1}</td>
         <td>{item.u17_boy_classes_relay_race_1}</td>
         <td>{item.u17_boy_dob_relay_race_1}</td>
         <td>{item.u17_girl_name_relay_race_1}</td>
         <td>{item.u17_girl_classes_relay_race_1}</td>
         <td>{item.u17_girl_dob_relay_race_1}</td>
         <td>{item.u14_boy_name_relay_race_2}</td>
         <td>{item.u14_boy_classes_relay_race_2}</td>
         <td>{item.u14_girl_classes_relay_race_2}</td>
         <td>{item.u14_boy_dob_relay_race_2}</td>
         <td>{item.u14_girl_name_relay_race_2}</td>
         <td>{item.u14_girl_dob_relay_race_2}</td>
         <td>{item.u17_boy_name_relay_race_2}</td>
         <td>{item.u17_boy_classes_relay_race_2}</td>
         <td>{item.u17_boy_dob_relay_race_2}</td>
         <td>{item.u17_girl_name_relay_race_2}</td>
         <td>{item.u17_girl_classes_relay_race_2}</td>
         <td>{item.u17_girl_dob_relay_race_2}</td>
         <td>{item.u14_boy_name_relay_race_3}</td>
         <td>{item.u14_boy_classes_relay_race_3}</td>
         <td>{item.u14_girl_classes_relay_race_3}</td>
         <td>{item.u14_boy_dob_relay_race_3}</td>
         <td>{item.u14_girl_name_relay_race_3}</td>
         <td>{item.u14_girl_dob_relay_race_3}</td>
         <td>{item.u17_boy_name_relay_race_3}</td>
         <td>{item.u17_boy_classes_relay_race_3}</td>
         <td>{item.u17_boy_dob_relay_race_3}</td>
         <td>{item.u17_girl_name_relay_race_3}</td>
         <td>{item.u17_girl_classes_relay_race_3}</td>
         <td>{item.u17_girl_dob_relay_race_3}</td>
         <td>{item.u14_boy_name_relay_race_4}</td>
         <td>{item.u14_boy_classes_relay_race_4}</td>
         <td>{item.u14_girl_classes_relay_race_4}</td>
         <td>{item.u14_boy_dob_relay_race_4}</td>
         <td>{item.u14_girl_name_relay_race_4}</td>
         <td>{item.u14_girl_dob_relay_race_4}</td>
         <td>{item.u17_boy_name_relay_race_4}</td>
         <td>{item.u17_boy_classes_relay_race_4}</td>
         <td>{item.u17_boy_dob_relay_race_4}</td>
         <td>{item.u17_girl_name_relay_race_4}</td>
         <td>{item.u17_girl_classes_relay_race_4}</td>
         <td>{item.u17_girl_dob_relay_race_4}</td>
         <td>{item.u14_boy_name_short_put}</td>
         <td>{item.u14_boy_classes_short_put}</td>
         <td>{item.u14_girl_classes_short_put}</td>
         <td>{item.u14_boy_dob_short_put}</td>
         <td>{item.u14_girl_name_short_put}</td>
         <td>{item.u14_girl_dob_short_put}</td>
         <td>{item.u17_boy_name_short_put}</td>
         <td>{item.u17_boy_classes_short_put}</td>
         <td>{item.u17_boy_dob_short_put}</td>
         <td>{item.u17_girl_name_short_put}</td>
         <td>{item.u17_girl_classes_short_put}</td>
         <td>{item.u17_girl_dob_short_put}</td>
         <td>{item.u14_boy_name_badminton}</td>
         <td>{item.u14_boy_classes_badminton}</td>
         <td>{item.u14_girl_classes_badminton}</td>
         <td>{item.u14_boy_dob_badminton}</td>
         <td>{item.u14_girl_name_badminton}</td>
         <td>{item.u14_girl_dob_badminton}</td>
         <td>{item.u17_boy_name_badminton}</td>
         <td>{item.u17_boy_classes_badminton}</td>
         <td>{item.u17_boy_dob_badminton}</td>
         <td>{item.u17_girl_name_badminton}</td>
         <td>{item.u17_girl_classes_badminton}</td>
         <td>{item.u17_girl_dob_badminton}</td>
         <td>{item.u14_boy_name_chess}</td>
         <td>{item.u14_boy_classes_chess}</td>
         <td>{item.u14_girl_classes_chess}</td>
         <td>{item.u14_boy_dob_chess}</td>
         <td>{item.u14_girl_name_chess}</td>
         <td>{item.u14_girl_dob_chess}</td>
         <td>{item.u17_boy_name_chess}</td>
         <td>{item.u17_boy_classes_chess}</td>
         <td>{item.u17_boy_dob_chess}</td>
         <td>{item.u17_girl_name_chess}</td>
         <td>{item.u17_girl_classes_chess}</td>
         <td>{item.u17_girl_dob_chess}</td>
         <td>{item.u14_boy_name_table_tenis}</td>
         <td>{item.u14_boy_classes_table_tenis}</td>
         <td>{item.u14_girl_classes_table_tenis}</td>
         <td>{item.u14_boy_dob_table_tenis}</td>
         <td>{item.u14_girl_name_table_tenis}</td>
         <td>{item.u14_girl_dob_table_tenis}</td>
         <td>{item.u17_boy_name_table_tenis}</td>
         <td>{item.u17_boy_classes_table_tenis}</td>
         <td>{item.u17_boy_dob_table_tenis}</td>
         <td>{item.u17_girl_name_table_tenis}</td>
         <td>{item.u17_girl_classes_table_tenis}</td>
         <td>{item.u17_girl_dob_table_tenis}</td>
      
             
              <td>{item.created_at}</td>
              <td>{item.updated_at}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        <button onClick={exportToPDF} style={{ marginTop: '20px' }}>
        Export to PDF
      </button>
        </div>
        </div>
        </div>
        </div>
        </div>
        </>
       
    );
}

export default ApiTable;
