import React, { useRef } from 'react';
import Home from './components/Home';
import NewHome from './components/NewHome';
import HomeAbout from './components/homeabout';
import Event from './components/event';
import DownloadPDf from './components/downloadpdf';

const IndexPage = () => {
  const eventRef = useRef(null); // Create a ref

  return (
    <>
      <NewHome />
      <HomeAbout />
      <div ref={eventRef}> {/* Attach the ref to the Event component */}
        <Event />
      </div>
      {/* <DownloadPDf/> */}
    </>
  );
};

export default IndexPage;
