import React, { useState } from 'react';


import aboutbitbetioimage from '../../images/counter-icon-3.webp';
import tabletenis from '../../images/table-tenis.png';
import badminton from '../../images/badminton.png';
import shotput from '../../images/shot-put.png';
import hurdle from '../../images/hurdles-race.png';
import longjump from '../../images/long-jump.png';
import relay from '../../images/running-man.png';
import sprints from '../../images/sprinter.png';
import EventBook from '../../images/Events.jpg';







const EventPage = () => {

  return (
  <>
<div className="i-anchor" id="events"></div>
 <section className="about-bitbetio" >
 <img
                      alt="icon"
                      src={EventBook}
                      // width={561}
                      height={20}
                      decoding="async"
                      data-nimg={1}
                      loading="lazy"
                      style={{ color: "transparent" }}
                    /> 
  {/* <div className="overlay pt-120 pb-120">
    <div className="shape-area"> */}
      {/* <img
        alt="image"
        src="images/IMAGE602.HTM"
        width={158}
        height={240}
        decoding="async"
        data-nimg={1}
        className="obj-1"
        loading="lazy"
        style={{ color: "transparent" }}
      /> */}
      {/* <img
        alt="image"
        src="images/CRYPTO_F.PNG"
        width={90}
        height={138}
        decoding="async"
        data-nimg={1}
        className="obj-2"
        loading="lazy"
        style={{ color: "transparent" }}
      /> */}
    {/* </div>
    <div className="container">
      <div className="row d-flex align-items-end"> */}
        {/* <div className="col-lg-6"> */}
          {/* <div className="image-area d-rtl left-side"> */}
            {/* <img
              alt="images"
              src={aboutbitbetioimage}
              width={948}
              height={860}
              decoding="async"
              data-nimg={1}
              className="max-un"
              loading="lazy"
              style={{ color: "transparent" }}
            /> */}
          {/* </div> */}
        {/* </div> */}
        {/* <div className="col-lg-12">
          <div className="section-text">
            {/* <h5 className="sub-title">A Next-Level Sports Betting</h5> */}
            {/* <h2 className="title" style={{color:"#000"}}>List of Event</h2>
             <p> 
              Bitbetio is a user-friendly, decentralised, peer-to-peer betting
              platform.
            </p>
          </div>
          <div className="row cus-mar">
            <div className="col-sm-3 col-3">
              <div className="single-item">
                <img
                  alt="images"
                  src={tabletenis}
                  width={160}
                  height={160}
                  decoding="async"
                  data-nimg={1}
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <h5 style={{color:"#000"}}>Table Tenis</h5>
              </div>
            </div>
            <div className="col-sm-3 col-3">
              <div className="single-item">
                <img
                  alt="images"
                  src={badminton}
                  width={160}
                  height={160}
                  decoding="async"
                  data-nimg={1}
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <h5 style={{color:"#000"}}>Badminton</h5>
              </div>
            </div>
            <div className="col-sm-3 col-3">
              <div className="single-item">
                <img
                  alt="images"
                  src={shotput}
                  width={160}
                  height={160}
                  decoding="async"
                  data-nimg={1}
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <h5 style={{color:"#000"}}>Shot Put</h5>
              </div>
            </div>
            <div className="col-sm-3 col-3">
              <div className="single-item">
                <img
                  alt="images"
                  src={hurdle}
                  width={160}
                  height={160}
                  decoding="async"
                  data-nimg={1}
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <h5 style={{color:"#000"}}>Hurdle</h5>
              </div>
            </div>
            <div className="col-sm-4 col-4">
              <div className="single-item">
                <img
                  alt="images"
                  src={longjump}
                  width={160}
                  height={160}
                  decoding="async"
                  data-nimg={1}
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <h5 style={{color:"#000"}}>Long Jump</h5>
              </div>
            </div>
            <div className="col-sm-4 col-4">
              <div className="single-item">
                <img
                  alt="images"
                  src={relay}
                  width={160}
                  height={160}
                  decoding="async"
                  data-nimg={1}
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <h5 style={{color:"#000"}}>Relay</h5>
              </div>
            </div>
            <div className="col-sm-4 col-4">
              <div className="single-item">
                <img
                  alt="images"
                  src={sprints}
                  width={160}
                  height={160}
                  decoding="async"
                  data-nimg={1}
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <h5 style={{color:"#000"}}>Sprints</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</section> 




  </>
  );};
  export default EventPage;