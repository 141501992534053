import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../logo.png'; // Adjust the path as necessary
import logo2 from '../images/Athletica Logo.png'; // Adjust the path as necessary
const Header = ({ eventRef,isAuthenticated, onLogout }) => {


    // const handleScroll = () => {
    //   if (eventRef && eventRef.current) {
    //     eventRef.current.scrollIntoView({ behavior: 'smooth' });
    //   }
    // };

    const handleScroll = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };
  
   

    return (



<>
  <div className="log-reg">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="modal fade" id="loginMod">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header justify-content-center">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                {/* <ul className="nav log-reg-btn justify-content-around">
                  <li className="bottom-area" role="presentation">
                    <button
                      className="nav-link false"
                      id="regArea-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#regArea"
                      type="button"
                      role="tab"
                    >
                      SIGN UP
                    </button>
                  </li>
                  <li className="bottom-area" role="presentation">
                    <button
                      className="nav-link false"
                      id="loginArea-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#loginArea"
                      type="button"
                      role="tab"
                    >
                      LOGIN
                    </button>
                  </li>
                </ul> */}
                <div className="tab-content">
                  <div
                    className="tab-pane fade false"
                    id="loginArea"
                    role="tabpanel"
                    aria-labelledby="loginArea-tab"
                  >
                    <div className="login-reg-content">
                      <div className="modal-body">
                        <div className="head-area">
                          <h6 className="title">Login Direetly With</h6>
                          <ul className="social-link d-flex align-items-center">
                            <li>
                              <a href="INDEX.HTM">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 320 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="INDEX.HTM">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 512 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="INDEX.HTM">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 448 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="INDEX.HTM">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 448 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="form-area">
                          <form action="#">
                            <div className="row">
                              <div className="col-12">
                                <div className="single-input">
                                  <label htmlFor="logemail">Email</label>
                                  <input
                                    type="text"
                                    id="logemail"
                                    placeholder="Email Address"
                                  />
                                </div>
                                <div className="single-input">
                                  <label htmlFor="logpassword">Password</label>
                                  <input
                                    type="text"
                                    id="logpassword"
                                    placeholder="Email Password"
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="remember-me">
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="left-area">
                                      <span className="checkbox-area d-flex">
                                        <input
                                          type="checkbox"
                                          defaultChecked=""
                                        />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>Remember Me</span>
                                      </span>
                                    </span>
                                  </label>
                                  <a href={404}>Forgot Password</a>
                                </div>
                              </div>
                              <span className="btn-border w-100">
                                <button className="cmn-btn w-100">LOGIN</button>
                              </span>
                            </div>
                          </form>
                          <div className="bottom-area text-center">
                            <p>
                              Not a member ?{/* */}
                              <a className="reg-btn" href="404-2">
                                Register
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade false"
                    id="regArea"
                    role="tabpanel"
                    aria-labelledby="regArea-tab"
                  >
                    <div className="login-reg-content regMode">
                      <div className="modal-body">
                        <div className="head-area">
                          <h6 className="title">Register On Bitbetio</h6>
                          <ul className="social-link d-flex align-items-center">
                            <li>
                              <a href="INDEX.HTM">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 320 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="INDEX.HTM">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 512 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="INDEX.HTM">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 448 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="INDEX.HTM">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth={0}
                                  viewBox="0 0 448 512"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="form-area">
                          <form action="#">
                            <div className="row">
                              <div className="col-12">
                                <div className="single-input">
                                  <label htmlFor="regemail">Email</label>
                                  <input
                                    type="email"
                                    id="regemail"
                                    placeholder="Email Address"
                                  />
                                </div>
                                <div className="single-input">
                                  <label htmlFor="regpassword">Password</label>
                                  <input
                                    type="password"
                                    id="regpassword"
                                    placeholder="Email Password"
                                  />
                                </div>
                                <div className="single-input">
                                  <label>Country</label>
                                  <div className="selector">
                                    <button
                                      id="headlessui-listbox-button-:R1llll6:"
                                      type="button"
                                      aria-haspopup="listbox"
                                      aria-expanded="false"
                                      data-headlessui-state=""
                                    >
                                      <span>United States</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="remember-me">
                                  <a href="404-3">Have a referral code?</a>
                                </div>
                              </div>
                              <span className="btn-border w-100">
                                <button className="cmn-btn w-100">
                                  SIGN UP
                                </button>
                              </span>
                            </div>
                          </form>
                          <div className="bottom-area text-center">
                            <p>
                              Already have an member ?{/* */}
                              <a className="log-btn" href="404-4">
                                Login
                              </a>
                            </p>
                          </div>
                          <div className="counter-area">
                            <div className="single">
                              <div className="icon-area">
                                <img
                                  alt="icon"
                                  srcSet="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsignup-counter-icon-1.1e4f1e80.png&w=64&q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsignup-counter-icon-1.1e4f1e80.png&w=128&q=75 2x"
                                  src="_NEXT/IMAGEF0E.HTM"
                                  width={60}
                                  height={60}
                                  decoding="async"
                                  data-nimg={1}
                                  loading="lazy"
                                  style={{ color: "transparent" }}
                                />
                              </div>
                              <div className="text-area">
                                <p>25,179k</p>
                                <p className="mdr">Bets</p>
                              </div>
                            </div>
                            <div className="single">
                              <div className="icon-area">
                                <img
                                  alt="icon"
                                  srcSet="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsignup-counter-icon-2.97b8fb1e.png&w=64&q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsignup-counter-icon-2.97b8fb1e.png&w=128&q=75 2x"
                                  src="_NEXT/IMAGEAFA.HTM"
                                  width={60}
                                  height={60}
                                  decoding="async"
                                  data-nimg={1}
                                  loading="lazy"
                                  style={{ color: "transparent" }}
                                />
                              </div>
                              <div className="text-area">
                                <p>6.65 BTC</p>
                                <p className="mdr">Total Won</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="betpopmodal">
    <div className="modal fade" id="betpop-up" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-8 col-xl-9 col-lg-11">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="top-item">
                    <button className="cmn-btn firstTeam">
                      Eagle will win
                    </button>
                    <button className="cmn-btn active draw">Draw</button>
                    <button className="cmn-btn lastTeam">Paeek will win</button>
                  </div>
                  <div className="select-odds d-flex align-items-center">
                    <h6>Select Odds</h6>
                    <div className="d-flex in-dec-val">
                      <input
                        type="text"
                        className="InDeVal2"
                        defaultValue="1.5"
                      />
                      <div className="btn-area">
                        <button className="plus2">
                          <img
                            alt="icon"
                            srcSet="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fup-arrow.8d4412c9.png&w=16&q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fup-arrow.8d4412c9.png&w=32&q=75 2x"
                            src="_NEXT/IMAGEF9A.HTM"
                            width={12}
                            height={8}
                            decoding="async"
                            data-nimg={1}
                            loading="lazy"
                            style={{ color: "transparent" }}
                          />
                        </button>
                        <button className="minus2">
                          <img
                            alt="icon"
                            srcSet="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdown-arrow.5372d4d9.png&w=16&q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdown-arrow.5372d4d9.png&w=32&q=75 2x"
                            src="_NEXT/IMAGE35C.HTM"
                            width={12}
                            height={8}
                            decoding="async"
                            data-nimg={1}
                            loading="lazy"
                            style={{ color: "transparent" }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mid-area">
                    <div className="single-area">
                      <div className="item-title d-flex align-items-center justify-content-between">
                        <span>Bet Value :</span>
                        <div className="selector">
                          <button
                            id="headlessui-listbox-button-:R6in76:"
                            type="button"
                            aria-haspopup="listbox"
                            aria-expanded="false"
                            data-headlessui-state=""
                          >
                            <span>ETH</span>
                          </button>
                        </div>
                      </div>
                      <div className="d-flex in-dec-val">
                        <input
                          type="text"
                          className="InDeVal1"
                          defaultValue="0.1"
                        />
                        <div className="btn-area">
                          <button className="plus">
                            <img
                              alt="icon"
                              srcSet="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fup-arrow.8d4412c9.png&w=16&q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fup-arrow.8d4412c9.png&w=32&q=75 2x"
                              src="_NEXT/IMAGEF9A.HTM"
                              width={12}
                              height={8}
                              decoding="async"
                              data-nimg={1}
                              loading="lazy"
                              style={{ color: "transparent" }}
                            />
                          </button>
                          <button className="minus">
                            <img
                              alt="icon"
                              srcSet="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdown-arrow.5372d4d9.png&w=16&q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdown-arrow.5372d4d9.png&w=32&q=75 2x"
                              src="_NEXT/IMAGE35C.HTM"
                              width={12}
                              height={8}
                              decoding="async"
                              data-nimg={1}
                              loading="lazy"
                              style={{ color: "transparent" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="single-area quick-amounts">
                      <div className="item-title d-flex align-items-center">
                        <p>Quick Amounts</p>
                      </div>
                      <div className="input-item">
                        <button className="quickIn">0.005</button>
                        <button className="quickIn">0.025</button>
                        <button className="quickIn">0.1</button>
                        <button className="quickIn">0.5</button>
                        <button className="quickIn">2.5</button>
                      </div>
                    </div>
                    <div className="single-area smart-value">
                      <div className="item-title d-flex align-items-center">
                        <p className="mdr">Smart Contact Value</p>
                      </div>
                      <div className="contact-val d-flex align-items-center">
                        <h4>0.1103</h4>
                        <h5>ETH</h5>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-area">
                    <div className="fee-area">
                      <p>
                        Winner will get: <span className="amount">0.179</span>
                        {/* */}ETH
                      </p>
                      <p className="fee">
                        Escrow Fee: <span>5%</span>
                      </p>
                    </div>
                    <div className="btn-area">
                      <button>Make (0.1 ETH) Bet</button>
                    </div>
                    <div className="bottom-right">
                      <p>Game Closes:</p>
                      <p className="date-area">
                        Mar <span>21,2021-1:00</span> Am
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <header className="header-section animated fadeInDown header-fixed false false">
        {/* <div className="overlay"> */}
          <div className="container-header">
            <div className="row d-flex header-area">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/">
                  <img
                    alt="logo"
                    src={logo}
                    width={400}
                  
                    decoding="async"
                    data-nimg={1}
                    className="logo mainlogo"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </a>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar-content"
                >
                  <i>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 448 512"
                      height="1em"
                      width="1em"
                      xmlns="../external.html?link=http://www.w3.org/2000/svg"
                    >
                      <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
                    </svg>
                  </i>
                </button>
                <div
                  className="collapse navbar-collapse justify-content-center"
                  id="navbar-content"
                >
                   <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="#home">
                        HOME
                      </a>
                    </li>
                    <li className="nav-item">
                      <a  className="nav-link" aria-current="page" onClick={handleScroll} href="#events">
                       EVENTS
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" onClick={handleScroll} href="#footer">
                        CONTACT
                      </a>
                    </li>
                  </ul> 
                  {/* <div className="right-area header-action d-flex align-items-center max-un">
                    <button
                      type="button"
                      className="login"
                      data-bs-toggle="modal"
                      data-bs-target="#loginMod"
                    >
                      Login
                    </button>
                    <button
                      type="button"
                      className="cmn-btn reg"
                      data-bs-toggle="modal"
                      data-bs-target="#loginMod"
                    >
                      Sign Up
                    </button>
                  </div> */}
                   <img
                    alt="logo"
                    src={logo2}
                    width={120}
                  
                    decoding="async"
                    data-nimg={1}
                    className="logo-ath"
                    loading="lazy"
                    style={{ color: "transparent" }}
                  />
                </div>
              </nav>
            </div>
          {/* </div> */}
        </div>
      </header>


</>


       
      
    );
};

export default Header;
