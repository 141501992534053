import React, { useEffect } from 'react';

const DownloadFile = () => {
  useEffect(() => {
    // Open the PDF in a new tab, which can help in bypassing popup blockers
    const downloadLink = document.createElement('a');
    downloadLink.href = 'https://sttar.in/ecce/Athletica-Rule-Book.pdf';
    downloadLink.target = '_blank'; // Open in a new tab
    downloadLink.download = ''; // Specify that it's a download
    document.body.appendChild(downloadLink);
    downloadLink.click(); // Trigger the download
    document.body.removeChild(downloadLink); // Clean up
  }, []);

  return (
    <div>
      <h2>Your download will start shortly...</h2>
      <p>If the download does not start, <a href="https://sttar.in/ecce/Athletica-Rule-Book.pdf" target="_blank" rel="noopener noreferrer" download>click here</a> to download manually.</p>
    </div>
  );
};

export default DownloadFile;
