import React from 'react';
import { CSVLink } from 'react-csv';

const MyTable = () => {
  // Sample data for the table
  const data = [
    { id: 1, name: 'John Doe', age: 25 },
    { id: 2, name: 'Jane Doe', age: 28 },
    { id: 3, name: 'Sam Smith', age: 22 },
  ];

  // Column headers for CSV
  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Age', key: 'age' },
  ];

  return (
    <div className='inner-page'>
      <h1>My Table</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Age</th>
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.name}</td>
              <td>{row.age}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Export button */}
      <CSVLink 
        data={data} 
        headers={headers} 
        filename="table_data.csv" 
        className="btn btn-primary" 
        target="_blank"
      >
        Export CSV
      </CSVLink>
    </div>
  );
};

export default MyTable;
